import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container, Paper, Typography, Button } from "@material-ui/core";
// import RecruiterPostedJobs from "../Pages/RecruiterPostedJobs";
// import FindCandidates from "../Pages/FindCandidates";
// import PostJob from "../Pages/PostJob/PostJob";
import bgImage from './blogging.svg'

const useStyles = makeStyles((theme) => ({
    firstSection: {
        // backgroundColor: "#1C2237",
        height: "50vh",
        ['@media (max-width:760px)']: {
            height: "90vh"
        }
    },
    paperSection: {
        height: "150px",
        paddingTop: "30px",
        backgroundColor: "#1C2237",
        boxShadow: "inset 0 0 10px #fff;"
    }
}));

export default function RecruiterDashboard() {
    const classes = useStyles();

    return (
        <Container style={{ paddingLeft: "0px", paddingRight: "0px", maxWidth: "none", backgroundColor:"#1C2237", marginBottom:"-15px" }}>
            <Grid container direction="row" justify="center" alignItems="center" className={classes.firstSection}>
                <Grid item md={6} xs={12}>
                    <center>
                        <h1 style={{ color: "white", fontSize: "3rem", fontFamily: "Poppins", textAlign: "center" }}>Find Your Right Fit</h1>
                        <Button variant="contained" style={{ backgroundColor: "#1888FF", color: "white", textTransform: "unset" }} href="/recruiter/findCandidates" target="_blank">Find Candidates</Button>
                    </center>
                </Grid>
                <Grid item md={6} xs={12}>
                    <img src={bgImage} alt="Image" style={{ height: "47vh", width: "auto" }} />
                </Grid>
            </Grid>
            <Container maxWidth="md">
                <Paper elevation={3} className={classes.paperSection}>
                    <center>
                        <h2 style={{fontFamily:"Poppins", color:"white"}}>Hire the right talent</h2>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item md={3} xs={5}>
                                <Button variant="contained" style={{ backgroundColor: "#1888FF", color: "white", textTransform: "unset" }} href="/recruiter/postjob" target="_blank">Post A Job</Button>

                            </Grid>
                            <Grid item md={3} xs={5}>
                                <Button variant="contained" style={{ backgroundColor: "#1888FF", color: "white", textTransform: "unset" }} href="/recruiter/posted-jobs" target="_blank">View Posted Jobs</Button>
                            </Grid>
                        </Grid>
                    </center>
                </Paper>
            </Container>
        </Container>
    )
}
