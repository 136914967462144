import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useSnackbar} from "material-ui-snackbar-provider";
import {makeStyles} from "@material-ui/core/styles";
import SignInStyle from "../../Styling/SignInStyle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {postRequest} from "../../Utils/fetch";
import history from "../../Utils/history";
import Button from "@material-ui/core/Button";
import {useParams} from "react-router-dom";

export default function ResetPasswordForm() {
    const [password,setPassword] = useState('');
    const classes = makeStyles(SignInStyle)();
    const { register, handleSubmit, errors, control } = useForm({
        defaultValues: {
            password: '',
            passwordConfirm: ''
        },
    });
    const snackbar = useSnackbar();
    const params = useParams();
    const onSubmit = async (data) => {
        const resp = await postRequest('/auth/resetpassword/' + params['token'] , {password: data['password'], passwordConfirm: data['passwordConfirm']});
        console.log(resp);
        if(resp['status'] !== 'success') {
            return snackbar.showMessage(resp['message'] || 'Some Error Occurred');
        }
        snackbar.showMessage(resp['message'] || 'Password Reset Successful');
        history.push('/login');
    }
    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        inputRef={register({
                            required: 'You must provide a password.',
                            minLength: {
                                value: 8,
                                message: 'Your password must be greater than 8 characters',
                            },
                        })}
                        onChange={(e) => setPassword(e.target.value)}
                        error={!!errors.password}
                        className={classes.field}
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    {errors.password && (
                        <span className={classes.error}>{errors.password.message}</span>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        inputRef={register({
                            required: 'You must provide a password.',
                            minLength: {
                                value: 8,
                                message: 'Your password must be greater than 8 characters',
                            },
                            validate: value => value === password || "The passwords do not match"
                        })}
                        error={!!errors.passwordConfirm}
                        className={classes.field}
                        variant="outlined"
                        required
                        fullWidth
                        name="passwordConfirm"
                        label="Confirm Password"
                        type="password"
                        id="password-confirm"
                        autoComplete="current-password"
                    />
                    {errors.passwordConfirm && (
                        <span className={classes.error}>{errors.passwordConfirm.message}</span>
                    )}
                </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Reset Password
            </Button>
        </form>
        )


}
