import SignIn from "../../Components/SignIn/index.";
import React from "react";
import SignInForm from "../../Components/SignIn/SignInForm";

export default function RecruiterLogin(props) {
    return (
        <SignIn heading={"Recruiter Sign In"}>
            <SignInForm loginType="recruiter"/>
        </SignIn>
    )
}
