import React from "react";
import {Button, Divider, Grid, IconButton, Paper, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import FormField from "./FormField";
import {FaPencilAlt, FaEdit} from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "80%",
        padding: theme.spacing(3),
        margin: "15px auto"
    },
    editButton: {
        height: "15px",
    },
    hr: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    cardHeader: {
        marginBottom: theme.spacing(3),
    },
    heading: {
        fontWeight: '400',
    },
}));

export default function Box({heading, editMode, setEditMode, onClickEdit, children}) {
    const classes = useStyles();




    return (
        <Paper className={classes.paper}>
            <Grid container justify={"space-between"} className={classes.cardHeader}>
                <h3 className={classes.heading}>{heading}</h3>
                <IconButton color={"primary"} aria-label="Edit" onClick={onClickEdit} size={"small"}>
                    <FaEdit className={classes.editButton}/>
                </IconButton>
            </Grid>
            <Divider className={classes.hr}/>
            {children}
        </Paper>
    )
}
