import SignIn from "../../Components/SignIn/index.";
import React from "react";
import ForgetPasswordForm from "./ForgetPasswordForm";

export default function ForgetPassword() {
    return (
        <SignIn heading={"Forget Password"}>
            <ForgetPasswordForm/>
        </SignIn>
    )
}
