import {Link, useRouteMatch} from "react-router-dom";
import {Redirect, Route, Switch} from "react-router-dom";
import PostJob from "../Pages/PostJob/PostJob";
import {useAuthState} from "../Context";
import React from "react";
import RecruiterLogin from "../Pages/RecruiterLogin";
import AppRoute from "./AppRoute";
import RecruiterPostedJobs from "../Pages/RecruiterPostedJobs";
import FindCandidates from "../Pages/FindCandidates";
import RecruiterSignup from "../Pages/RecruiterSignup";
import EditJob from "../Pages/EditJob";
import ListAppliedCandidates from "../Pages/ListAppliedCandidates";
import RecruiterDashboard from "../Pages/RecruiterDashboard";

const routes = [
    {
        path: '/postjob',
        component: PostJob,
        isPrivate: true,
        profileType: 'recruiter'
    },
    {
        path: "/findCandidates",
        component: FindCandidates,
        isPrivate: false,
        profileType: 'recruiter'
    },
    {
        path: '/login',
        component:RecruiterLogin,
        isPrivate: false,
    },
    {
        path: '/signup',
        component: RecruiterSignup,
        isPrivate: false,
    },
    {
        path: '/posted-jobs',
        component: RecruiterPostedJobs,
        isPrivate: true,
        profileType: 'recruiter'
    },
    {
        path: '/edit-job/:jobId',
        component: EditJob,
        isPrivate: true,
        profileType: 'recruiter'
    },
    {
        path: '/list-applied-users/:jobId',
        component: ListAppliedCandidates,
        isPrivate: true,
        profileType: 'recruiter'
    },
    {
        path: '/',
        component: RecruiterDashboard,
        isPrivate: true,
        profileType: 'recruiter'
    }
]

export default function RecruiterAppRoutes() {
    let { path, url } = useRouteMatch();
    return (
        <Switch>
            {/*{routes.map(({component:Component,isPrivate,...route}) => (*/}
            {/*    <Route*/}
            {/*        key={route.path}*/}
            {/*        path={`${path}${route.path}`}*/}
            {/*        render={(props) => (*/}
            {/*                isPrivate && userDetails && (userDetails.isLoggedIn !== true || userDetails.profileType !== 'recruiter') ? (*/}
            {/*                <Redirect to={{ pathname: `${path}/login` }} />*/}
            {/*            ) : (*/}
            {/*                <Component {...props} />*/}
            {/*            )*/}
            {/*        )}*/}
            {/*    />*/}
            {/*))}*/}
            {routes.map((route) => (
                <AppRoute
                    key={route.path}
                    path={path + route.path}
                    rootPath={path}
                    component={route.component}
                    isPrivate={route.isPrivate}
                    profileType={route.profileType}
                />
            ))}
        </Switch>
    )
}
