import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, TextField,
    Typography
} from "@material-ui/core";
import React from "react";
import {postRequest} from "../../Utils/fetch";
import {useSnackbar} from "material-ui-snackbar-provider";

export default function SendEmailModal({ids:userIds, open, setOpen, ...props}) {
    const [subject, setSubject] = React.useState('');
    const [emailText, setEmailText] = React.useState('');
    const [error, setError] = React.useState('');
    const snackbar = useSnackbar();

    const handleClose = () => {
        setOpen(false);
    };
    const handleSendEmail = async () => {
        if(!subject || subject === '') return setError('Subject is Empty');
        if(!emailText || emailText === '') return setError('Body is Empty');
        let failedEmails = [];
        console.log(userIds)
        for (let user of userIds) {
            const reqBody = {
                id: user.user,
                subject: subject,
                emailText
            }
            const resp = await postRequest('/general/sendEmail', reqBody);
            if(resp.status !== 'success') {
                failedEmails.push(user.name);
            } else {
            }
        }
        if(failedEmails.length !== 0) alert(`Unable to send mail to following users- ${failedEmails.toString()}`);
        else snackbar.showMessage("Emails sent successfully");
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="Send Email" fullWidth maxWidth="sm">
                <DialogTitle id="send-email-title">Send Email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span style={{color: "red", fontSize: '0.8em'}}>{error}</span>
                    </DialogContentText>
                    {/*<TextField disabled fullWidth type="email" label="To" defaultValue={emails.toString()}/>*/}
                    <TextField fullWidth label="Subject" value={subject} onChange={(e) => {setSubject(e.target.value)}}/>
                    <TextField fullWidth label="Body" value={emailText} multiline rows={3} onChange={(e) => {setEmailText(e.target.value)}}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSendEmail} color="primary" variant={"contained"}>
                        Send Email
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
