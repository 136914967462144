import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Card, CardActions, CardContent, Grid, InputLabel} from "@material-ui/core";
import ApplyNowBtn from "../Pages/FindJobs/ApplyNowBtn";
import experienceOptions from "../assets/experiences.json";
import educationOptions from "../assets/educations.json";
import jobTypeOptions from "../assets/jobTypes.json";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginBottom: "1rem"
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    cardContentSection: {
        borderTop: "1px solid #eaeff5",
        borderBottom: "1px solid #eaeff5"
    },
    actionButtons: {
        margin: "3px"
    }
});

const JobCard= React.forwardRef(({cardType = 'applicant',editJob,deleteJob,viewCandidates, ...props}, ref) => {
    const classes = useStyles();
    // console.log(props.job);
    let jobType, experience, education;
    if(props.job.jobType)jobType = props.job.jobType.toString();
    if(props.job.experience) experience = props.job.experience.toString();
    if(props.job.education) education = props.job.education.toString();
    for (const jobTypeOption of jobTypeOptions){
        if(jobTypeOption["ValueId"] === jobType){jobType = jobTypeOption["TextName"]; break;}
    }
    
    for (const experienceOption of experienceOptions){
        if(experienceOption["ValueId"] === experience){experience = experienceOption["TextName"]; break;}
    }

    for (const educationOption of educationOptions){
        if(educationOption["ValueId"] === education){education = educationOption["TextName"]; break;}
    }
    let formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
    });
    return (
        <Grid ref={ref} container direction="row" justify="center" alignItems="center">
            <Grid item md={7} xs={11}>
                <Card className={classes.root}>
                    <Link
                        to={{
                            pathname: "/jobs/" + props.job._id,
                        }}
                        style={{textDecoration: "none", color: "black"}}
                    >
                        <h3 style={{
                            marginLeft: "1rem",
                            marginTop: "0.5rem",
                            color: "#0047ab",
                            fontFamily: "Poppins"
                        }}>{props.job.title}</h3>
                        <CardContent className={classes.cardContentSection}>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item md={5} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i
                                        className="fas fa-rupee-sign">MONTHLY:</i></InputLabel>
                                    <span> {props.job.minsalary? formatter.format(props.job.minsalary): "" }- {props.job.maxsalary ? formatter.format(props.job.maxsalary) : ""}</span>
                                </Grid>
                                <Grid item md={2}></Grid>
                                <Grid item md={5} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i className="fas fa-briefcase">JOB
                                        TYPE:</i></InputLabel>
                                    <span> {jobType}</span>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item md={5} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i className="fas fa-building">COMPANY:</i></InputLabel>
                                    <span> {props.job.company}</span>
                                </Grid>
                                <Grid item md={2} xs={false}></Grid>
                                <Grid item md={5} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i
                                        className="fas fa-history">EXPERIENCE:</i></InputLabel>
                                    <span> {experience}</span>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item md={5} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i className="fas fa-user-graduate">EDUCATION:</i></InputLabel>
                                    <span> {education}</span>
                                </Grid>
                                <Grid item md={2} xs={false}></Grid>
                                <Grid item md={5} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i
                                        className="fas fa-user-tie">CATEGORY: </i></InputLabel>
                                    <span>{props.job.category}</span>
                                </Grid>
                            </Grid>
                            <div>
                                <InputLabel style={{display: "inline"}}><i className="fas fa-address-book">CITIES:</i></InputLabel>{" "}
                                <span> {props.job.city && props.job.city.toString()}</span>
                            </div>
                        </CardContent>
                    </Link>
                        <CardActions>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item md={8} xs={12}>
                                    {cardType === 'applicant' && <ApplyNowBtn jobId={props.job._id}></ApplyNowBtn>}
                                    {cardType === 'creater' && <>
                                        <Button variant="outlined" size="small" className={classes.actionButtons} onClick={() => {if (viewCandidates) viewCandidates(props.job._id)}}>View Candidates</Button>
                                        <Button variant="outlined" size="small" className={classes.actionButtons} onClick={() => {if (editJob) editJob(props.job._id)}}>Edit</Button>
                                        <Button variant="outlined" size="small" className={classes.actionButtons} onClick={() => {if (deleteJob) deleteJob(props.job._id)}}>Delete</Button>
                                    </>
                                    }
                                </Grid>
                                <Grid item md={3} xs={10}>
                                    <p style={{fontSize: "0.7rem"}}>
                                        Posted on: {props.job.date.substr(0, 10)}
                                    </p>
                                </Grid>
                            </Grid>
                        </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
})

export default JobCard;
