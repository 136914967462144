import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom"; 
import { makeStyles } from "@material-ui/core/styles";
import {
  InputAdornment,
  TextField,
  Grid,
  Container,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import JobCard from "../../Components/JobCard";
import SortDialog from "../../Components/SortDialog/SortDialog";
// import SortDialog from './SortDialog';
import FilterDialog from "./FilterDialog";
import Loader from "../../Components/Loader";
import "./FindJobs.css";
import { getRequest } from "../../Utils/fetch";
import CitiesList from "../../assets/CitiesList.json"
import {useAuthState} from "../../Context";

const useStyles = makeStyles((theme) => ({
  searchWidth: {
    width: "100%",
    background: "white",
    borderRadius: "4px",
  },
  searchButton: {
    paddingTop: "15px",
    paddingBottom: "15px",
    background: "white",
    borderRadius: "4px",
    "&:hover": {
      color: "white",
      borderColor: "white",
    },
  },
  lowerButtons: {
    marginBottom: "10px",
  },
  selectCity: {
    minWidth: 130,
    maxWidth: "100%",
    paddingLeft: 4
  },
  filterAndSortButton: {
    width: "100%",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
  },
}));

function FindJobs() {
  const classes = useStyles(); //Select City
  const [jobsList, setJobsList] = useState([]);
  const history = useHistory()
  const urlquery = new URLSearchParams(useLocation().search)
  const {user} = useAuthState();
  let profileType = 'applicant'
  if(user && user.profileType === 'recruiter') profileType = 'recruiter';
  const [city, setCity] = useState(urlquery.get("city"));
  // let city = urlquery.get("city");
  let salary = urlquery.get("salary");
  if(!salary) salary=0;
  let category = urlquery.get("category"); 
  if(!category) category="-1";
  let jobType = urlquery.get("jobType");
  if(!jobType) jobType="-1";
  let experience = urlquery.get("experience");
  if(!experience) experience="-1";
  let education = urlquery.get("education");
  if(!education) education="-1";
  const [selectedValueFilter, setSelectedValueFilter] = React.useState(
    {salary, category, experience, jobType, education}
  );
  const [loading, setLoading] = useState(true);
  const [jobNotAvailable, setJobNotAvailable] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false)

  const initializeAfterChange= ()=> {
    setLoading(true);
    setJobNotAvailable(false);
    setPageNumber(1);
  }

  const handleCityChange = (event, newCity) => {
    initializeAfterChange();
    urlquery.delete("city")
    if(newCity) urlquery.append("city", newCity.TextName) 
    // else urlquery.delete("city")
    if(!newCity)setCity(-1);
    else setCity(newCity.TextName);
    history.push({search: urlquery.toString()})
    console.log(city);
    // setRedirect(true);
  };

  const [openSort, setOpenSort] = React.useState(false); //Sort Dialog
  const [selectedValueSort, setSelectedValueSort] = React.useState("-date");

  const handleClickOpenSort = () => {
    setOpenSort(true);
  };

  const handleCloseSort = (value) => {
    setOpenSort(false);
    initializeAfterChange();
    setSelectedValueSort(value);
  };

  const handleFilterChange = (newValue) => {
    initializeAfterChange();
    console.log(newValue);
    urlquery.delete("category");
    if(newValue.category !== "-1") urlquery.append("category", newValue.category) 
    category = newValue.category;
    urlquery.delete("jobType");
    if(newValue.jobType !== "-1") urlquery.append("jobType", newValue.jobType) 
    jobType = newValue.jobType;
    urlquery.delete("experience");
    if(newValue.experience !== "-1") urlquery.append("experience", newValue.experience) 
    experience = newValue.experience;
    urlquery.delete("education");
    if(newValue.education !== "-1") urlquery.append("education", newValue.education) 
    education = newValue.education;
    urlquery.delete("salary");
    if(newValue.salary !== 0) urlquery.append("salary", newValue.salary) 
    salary = newValue.salary;
    setSelectedValueFilter({salary, category, jobType, experience, education});
    history.push({search: urlquery.toString()})
  }
  const [openFilter, setOpenFilter] = React.useState(false); //Filter Dialog

  const handleClickOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = (value) => {
    setOpenFilter(false);
  };

  const observer = useRef()
  const lastJobElementRef = useCallback(node => {         
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  useEffect(async () => {
    //get api request
    let params = {sort: selectedValueSort, page: pageNumber, limit: 3};
    let urlcity = urlquery.get("city");
    if(urlcity) params = { ...params, city: urlcity };
    salary = urlquery.get("salary");
    if(salary) params = { ...params, 'minsalary[lte]': parseInt(salary), 'maxsalary[gte]': parseInt(salary) };
    category = urlquery.get("category");
    if(category) params = { ...params, 'category': category };
    experience = urlquery.get("experience");
    if(experience) params = { ...params, 'experience[gte]': parseInt(experience) };
    education = urlquery.get("education");
    if(education) params = { ...params, 'education': parseInt(education) };
    jobType = urlquery.get("jobType");
    if(jobType) params = { ...params, 'jobType': parseInt(jobType) };
    let status = await getRequest("/jobs/all", params);
    console.log(status);
    if (status.status === "success") {
      if(pageNumber === 1)setJobsList(status.data.data);
      else setJobsList(jobsList => {
        return [...jobsList, ...status.data.data]
      })
      setHasMore(status.data.data.length > 0)
      setLoading(false);
      if(pageNumber===1 && status.data.data.length === 0){
        setJobNotAvailable(true);
      }else setJobNotAvailable(false);
    }
  }, [ selectedValueSort, pageNumber, history, selectedValueFilter, city]);// city, selectedValueFilter]);

  return (
    <React.Fragment>
      <div className="find-job-bg">
        <Container>
          <h3 className="browse-job">Browse Job</h3>
          {/* <Grid container direction="row" justify="center" alignItems="center" className="search-section">
          <Grid item md={6} xs={12}>
            <TextField
              className={classes.searchWidth}
              label="Search Jobs here"
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="fas fa-search"></i>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={1} xs={false}>
            <Button variant="outlined" className={classes.searchButton}>
              Search
            </Button>
          </Grid>
        </Grid> */}
        </Container>
      </div>
      <Container>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.lowerButtons}
        >
          <Grid item md={2} xs={3}>
          <Button variant="outlined" className={classes.filterAndSortButton} onClick={handleClickOpenFilter}>
            <i className="fas fa-filter"></i>Filter
          </Button>
          <FilterDialog selectedValue={selectedValueFilter} open={openFilter} onClose={handleCloseFilter} setSelectedValueFilter={handleFilterChange}/>
        </Grid>
          <Grid item md={2} xs={3}>
            <Button
              variant="outlined"
              className={classes.filterAndSortButton}
              onClick={handleClickOpenSort}
            >
              <i className="fas fa-sort"></i>Sort
            </Button>
            <SortDialog
              selectedValue={selectedValueSort}
              open={openSort}
              onClose={handleCloseSort}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            {/* <FormControl variant="outlined" className={classes.selectCity}>
            <InputLabel id="select-city-label">Select City</InputLabel>
            <Select
              labelId="select-city-label"
              id="select-city-id"
              value={city}
              onChange={handleCityChange}
              label="Select City"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"delhi"}>Delhi</MenuItem>
            </Select>
          </FormControl> */}
            <Autocomplete
              disablePortal
              className={classes.selectCity}
              id="select-city-id"
              options={CitiesList}
              getOptionLabel={(option) => option.TextName}
              onChange={handleCityChange}
              renderInput={(params) => <TextField {...params} label="City" />}
            />
          </Grid>
        </Grid>

        {(
          <div>
            {jobsList.map((job, index) => {
              if (jobsList.length === index+1){
                return <JobCard ref={lastJobElementRef} key={index} job={job} cardType={profileType}/>;
              }else {
                return <JobCard key={index} job={job} cardType={profileType}/>;
              }
            })}
          </div>
        )}
        {
          jobNotAvailable && (<div style={{textAlign:"center", fontFamily:"Poppins", fontSize:"2rem"}}>No Job Available</div>)
        }

        {loading && (
          <Loader loading={loading} />
        )}
      </Container>
    </React.Fragment>
  );
}

export default FindJobs;
