import React from 'react';
import SignUpForm from "./SignUpForm";
import {useLocation} from "react-router";

export default function SignUp(props) {
    const location = useLocation();
    let signUpType = location.state ? location.state.signupType : 'password';
    let token = location.state? location.state.token : null;
    return (
        <>
            <SignUpForm history={props.history} type={signUpType} token={token}/>
        </>
    );
}
