import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {useAuthDispatch, useAuthState} from "../../Context";
import {useForm} from "react-hook-form";
import SignInStyle from "../../Styling/SignInStyle";
import {makeStyles} from "@material-ui/core/styles";
import {postRequest} from "../../Utils/fetch";
import {useSnackbar} from "material-ui-snackbar-provider";
import history from "../../Utils/history";

export default function ForgetPasswordForm() {
    const [email, setEmail] = useState('');

    const dispatch = useAuthDispatch();
    const {loading, errorMessage} = useAuthState();

    const classes = makeStyles(SignInStyle)();

    const { register, handleSubmit, errors, control } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            email: '',
        },
    });
    const snackbar = useSnackbar();
    const onSubmit = async (data) => {
        console.log(data);
        const resp = await postRequest('/auth/forgetpassword', {email: data['email']});
        console.log(resp);
        if(resp['status'] !== 'success') {
            return snackbar.showMessage(resp['message'] || 'Some Error Occurred');
        }
        snackbar.showMessage(resp['message'] || 'Check your email');
        history.push('/');
    }

    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            {errorMessage ? <p className={classes.error}>{errorMessage}</p> : null}
            <TextField
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
                inputRef={register({
                    required: 'You must provide the email address!',
                    pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'You must provide a valid email address!',
                    },
                })}
                error={!!errors.email}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
            />
            {errors.email && (
                <span className={classes.error}>{errors.email.message}</span>
            )}
            <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Forgot Password
            </Button>
        </form>
    );
}
