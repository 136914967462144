import React from 'react'
import './Card.css'

const Card = ({icons,heading,text}) => {
    const classNames = ['home__card', 'home__card--shadow', 'home__card--hover'];
    const Icon = icons[0];
    return (
        <div className={classNames.join(' ')}>
            <div className='home__card--icon'>
                <Icon/>
            </div>
            <h4 className='home__card--heading'>{heading}</h4>
            <p className='home__card--text'>{text}</p>
        </div>
    )
}
export default Card;
