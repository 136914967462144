import {Container} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {deleteRequest, getRequest} from "../../Utils/fetch";
import {useSnackbar} from "material-ui-snackbar-provider";
import Loader from "../../Components/Loader";
import JobCard from "../../Components/JobCard";
import history from "../../Utils/history";

export default function RecruiterPostedJobs(props) {
    const [loading, setLoading] = useState(true);
    const [postedJobs, setPostedJobs] = useState([]);
    const snackbar = useSnackbar();
    const viewCandidates = (jobId) => {
        history.push('/recruiter/list-applied-users/' + jobId)
    }
    const editJob = (jobId) => {
        history.push('/recruiter/edit-job/' + jobId);
    }
    const deleteJob = async (jobId) => {
        const resp = await deleteRequest('/jobs/jobs',{jobId: jobId});
        if(resp['status'] !== 'success') {
            return snackbar.showMessage(resp['message'] || 'Some Error Occurred');
        }
        snackbar.showMessage(resp['message'] || 'Job Deleted Successfully');
        history.go(0);
    }
    useEffect(async () => {
        let params = {recruiter: 1}
        let resp = await getRequest('/jobs/all', params);
        if(resp['status'] !== 'success') {
            snackbar.showMessage(resp['message'] || "Some Error Occurred");
        } else {
            if(resp['data'] && resp['data']['data'])
                setPostedJobs(resp['data']['data']);
            setLoading(false);
        }
    },[snackbar]);
    return (
        <>
            <Container>
                <Loader loading={loading} />
                {!loading && postedJobs.length === 0 && (<div style={{textAlign:"center", fontFamily:"Poppins", fontSize:"2rem"}}>No Job Available</div>)}
                {postedJobs.map((job, index) => {
                    return <JobCard key={index} job={job} cardType="creater" editJob={editJob} deleteJob={deleteJob} viewCandidates={viewCandidates}/>;
                })}
            </Container>
        </>
    );
}
