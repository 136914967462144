import React from 'react';
import Card from './Card';
import {FiUserPlus, FiSearch} from "react-icons/fi";
import {FaRegHandPointer} from 'react-icons/fa';
import { ImProfile } from "react-icons/im";
import './Home.css';

function HowItWorks() {
    return (
        <div className='home__how-it-works home__section'>
            {/*<h1>How It Works</h1>*/}
            <div className="home__section__heading">
                <h1>How It Works</h1>
            </div>
            <div className='home__how-it-works__cards'>
                <Card icons={[FiUserPlus]} heading='Account' text='First you have to create an Account in here.'/>
                <Card icons={[ImProfile]} heading='Create Resume' text='Create a free digital resume.'/>
                <Card icons={[FiSearch]} heading='Browse Job' text='Find your best job by using search or categories.'/>
                <Card icons={[FaRegHandPointer]} heading='Apply Job'
                      text='Finally you apply your job and enjoy your work.'/>
            </div>
        </div>
    );
}

export default HowItWorks;
