import React, {useState} from "react";
import Box from './Box';
import {Button, Typography} from "@material-ui/core";
import FormField from "./FormField";
import {makeStyles} from "@material-ui/core/styles";
import jobCategories from './../../assets/jobCategories.json'

const useStyles = makeStyles((theme) => ({
    editFormButtons: {
        margin: theme.spacing(1)
    }
}))

export default function DesiredJobDetails(props) {
    const {data:finalDetails, setFinalData: setFinalDetails} = props;
    const classes = useStyles();
    const [editMode, setEditMode] = useState(false);
    // const [finalDetails,setFinalDetails] = useState({
    //     "jobRole": "Developer",
    //     "jobLocation": "Delhi",
    //     "department": "Web",
    //     "jobType": "Full Time",
    //     "salary": "1 lakh"
    // });
    const [details, setDetails] = useState(finalDetails);
    const handleDetailsChange = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value,
        })
    };
    // const jobCategories = [["accountant","accountant"],"backOffice","businessDev","computerOperator","counsellor","counterSales","dataEntry","delivery","digitalMarketing","finance","hardwareEngineer","hotelManagement","housekeeping","hrExecutive","marketing","receptionist","retail","sales","securityGuard","technician","telecalling","trainer","other"];
    const fields = {
        "Department" : [
            <select name="department" value={details["department"]} onChange={handleDetailsChange}>
                {jobCategories.map((field) => (<option defaultValue={field} key={field}>{field}</option> ))}
            < /select>,
            <Typography>{finalDetails["department"]}</Typography>
        ],
        "Job Location" : [
            <input type="text" placeholder="Enter Your Desired Job Location" value={details["location"]} onChange={handleDetailsChange} name="location"/>,
            <Typography>{finalDetails["location"]}</Typography>
        ],
        "Job Role": [
            <input type="text" placeholder="Enter Your Desired Role" value={details["role"]} onChange={handleDetailsChange} name="role"/>,
            <Typography>{finalDetails["role"]}</Typography>
        ],
        "Job Type": [
            // <input type="text" placeholder="Enter " value={details["type"]} onChange={handleDetailsChange} name="type"/>
            <select name="type" value={details['type']} onChange={handleDetailsChange} >
                <option defaultValue="Full Time">Full Time</option>
                <option defaultValue="Part Time">Part Time</option>
            </select> ,
            <Typography>{finalDetails["type"]}</Typography>
        ],
        "Salary": [
            <input type="text" placeholder="Enter Salary" value={details["salary"]} onChange={handleDetailsChange} name="salary"/>,
            <Typography>{finalDetails["salary"]}</Typography>
        ],
    }

    const onClickEdit = () => {
        setEditMode(!editMode);
        setDetails(finalDetails);
    };
    const onClickSave = () => {
        console.log(details);
        setFinalDetails('desiredJobDetails',details);
        setEditMode(false);
    }
    const editFormButtons =
        <div>
            <Button variant="contained" color="primary" className={classes.editFormButtons} onClick={onClickSave}>Save</Button>
            <Button variant="contained" color="default" className={classes.editFormButtons} onClick={onClickEdit}>Cancel</Button>
        </div>

    return (
        <Box heading={"Desired Job Details"} setEditMode={setEditMode} editMode={editMode} onClickEdit={onClickEdit}>
            {Object.keys(fields).map((field,index) => (
                <FormField key={index} field={field} formElement={fields[field][0]} value={fields[field][1]} editMode={editMode}/>
            ))}
            <FormField field="" formElement={editFormButtons} value={""} editMode={editMode}/>
        </Box>
    )
}
