import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Button,
    Card, Checkbox,
    Container, Divider, FormControlLabel, FormGroup,
    makeStyles,
    Paper, Slider,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterOptions from "../../Components/FilterDialog/FilterOptions";
import React from "react";
import cityList from '../../assets/CitiesList.json';
import educationOptions from "../../assets/educations.json";
import experienceOptions from "../../assets/experiences.json";
import jobCategoriesOptions from "../../assets/jobCategories.json";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    heading: {},
    details: {
        height: "200px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
    }
}));
const genderOptions = [{ "TextName": "Male", "ValueId": "male" }, { "TextName": "Female", "ValueId": "female" }, { "TextName": "Other", "ValueId": "other" }];
const jobCategoriesList = jobCategoriesOptions.map((item) => {
    return {TextName: item, ValueId: item}
})
const cityOptions = cityList.map((city) => {
    return {TextName: city.TextName, ValueId: city.TextName};
})
export default function SideFilterBox({selectedValue, setSelectedValueFilter, ...props}) {
    const classes = useStyles();
    const [jobCategories, setJobCategories] = React.useState(selectedValue.jobCategories);
    const [gender, setGender] = React.useState(selectedValue.gender);
    const [experience, setExperience] = React.useState(selectedValue.experience);
    const [city, setCity] = React.useState(selectedValue.city);
    let tempCity = selectedValue.city;
    const [education, setEducation] = React.useState(selectedValue.education);
    const [salary, setSalary] = React.useState(selectedValue.salary);
    const changeJobCategories = (value) => {
        setJobCategories(value);
    }
    const changeGender = (value) => {
        setGender(value);
    }
    const changeCity = (value) => {
        console.log(value)
        setCity(value);
        tempCity = value;
    }
    const changeEducation = (event) => {
        setEducation({ ...education, [event.target.name]: event.target.checked });
    }
    const changeSalary = (event, newValue) => {
        setSalary(newValue);
    }
    function salarytext(value) {
        return `${value}k`;
    }
    const changeExperience = (value) => {
        setExperience(value);
    }
    const setFilterOption = () => {
        setSelectedValueFilter({ jobCategories, gender, city, education, salary, experience });
    }
    return (
        <Container maxWidth={"sm"}>
            <Paper elevation={2} style={{paddingBottom: "5px"}}>
                <Typography variant={"h5"} style={{padding: "15px"}}>Filters</Typography>
                <Divider/>
                <div className={classes.root}>
                    <Accordion id="accordion-category">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="category-content">
                            <Typography className={classes.heading}>Category</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <FilterOptions option={jobCategoriesList} handleChange={changeJobCategories} value={jobCategories}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion id="accordion-gender">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="category-gender">
                            <Typography className={classes.heading}>Gender</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <FilterOptions option={genderOptions} handleChange={changeGender} value={gender}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion id="accordion-city">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="category-city">
                            <Typography className={classes.heading}>City</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <FilterOptions option={cityOptions} handleChange={changeCity} value={tempCity}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion id="accordion-education">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="category-education">
                            <Typography className={classes.heading}>Education</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={education["1"]} onChange={changeEducation} name="1" />}
                                    label="10th or Below"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={education["2"]} onChange={changeEducation} name="2" />}
                                    label="10+2 or Below"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={education["3"]} onChange={changeEducation} name="3" />}
                                    label="Diploma"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={education["4"]} onChange={changeEducation} name="4" />}
                                    label="Graduate"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={education["5"]} onChange={changeEducation} name="5" />}
                                    label="Post Graduate"
                                />
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion id="accordion-salary">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="category-content">
                            <Typography className={classes.heading}>Salary</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <Slider value={salary} onChange={changeSalary} valueLabelDisplay="auto" aria-labelledby="range-slider-salary" getAriaValueText={salarytext} style={{width: "80%",marginTop: "30px",marginLeft: "auto", marginRight:"auto"}}
                                marks={[
                                    {
                                        value: 0,
                                        label: 'Rs. 0',
                                    },
                                    {
                                        value: 100,
                                        label: 'Rs. 100000',
                                    },
                                ]}
                            />
                            <Typography gutterBottom>
                                Rs. {salary[0] * 1000} to Rs. {salary[1] * 1000}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion id="accordion-experience">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="category-experience">
                            <Typography className={classes.heading}>Experience</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <FilterOptions option={experienceOptions} handleChange={changeExperience} value={experience}/>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div style={{display: "block", padding: "10px"}}>
                    <Button variant={"contained"} color={"primary"} fullWidth onClick={setFilterOption}>Apply</Button>
                </div>

            </Paper>
        </Container>
    )
}
