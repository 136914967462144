import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import React, {useState} from "react";
import {loginUser, useAuthDispatch, useAuthState} from "../../Context";
import {useForm} from "react-hook-form";
import SignInStyle from "../../Styling/SignInStyle";
import {makeStyles} from "@material-ui/core/styles";
import GoogleLogin from "react-google-login";
import {loginGoogle} from "../../Context/actions";
import GoogleSignInBtn from "../GoogleSignInBtn";
import history from "../../Utils/history";
import {useSnackbar} from "material-ui-snackbar-provider";

export default function SignInForm(props) {
    const {loginType='applicant'} = props
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useAuthDispatch();
    const {loading, errorMessage} = useAuthState();

    const classes = makeStyles(SignInStyle)();

    const {register, handleSubmit, errors, control} = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            email: '',
            password: ''
        },
    });
    const snackbar = useSnackbar();
    const onSubmit = async (data) => {
        console.log(data);

        try {
            let response = await loginUser(dispatch, {email, password, loginType});
            if (!response.user) return;
            snackbar.showMessage("Successfully logged in" );
            if(loginType === 'applicant') history.push('/findjobs');
            else if(loginType === 'recruiter') history.push('/recruiter/')
        } catch (error) {
            console.log(error);
        }
    };
    const handleGoogleLogin = async (googleData) => {

        try {
            const {message, error} = await loginGoogle(dispatch, {token: googleData.tokenId, profileType: loginType});
            if (error) {
                if (error === 'selectAccountType') {
                    console.log("selectAccountType")
                } else if (error === 'userNotFound') {
                    snackbar.showMessage("You need to create a account.");
                } else if(error === 'noProfile') {
                    snackbar.showMessage("Account Not Found");
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            {errorMessage ? <p className={classes.error}>{errorMessage}</p> : null}
            <TextField
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
                inputRef={register({
                    required: 'You must provide the email address!',
                    pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'You must provide a valid email address!',
                    },
                })}
                error={!!errors.email}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
            />
            {errors.email && (
                <span className={classes.error}>{errors.email.message}</span>
            )}
            <TextField
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                inputRef={register({
                    required: 'You must provide a password.',
                    minLength: {
                        value: 8,
                        message: 'Your password must be greater than 8 characters',
                    },
                })}
                error={!!errors.password}
                disabled={loading}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
            />
            {errors.password && (
                <span className={classes.error}>{errors.password.message}</span>
            )}

            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                render={(props) => (<GoogleSignInBtn btnText="Sign in with Google" {...props}/>)}
                onSuccess={handleGoogleLogin}
                // onFailure={handleGoogleLogin}
                cookiePolicy={'single_host_origin'}
            />

            {/*<FormControlLabel*/}
            {/*  control={<Checkbox value="remember" color="primary" />}*/}
            {/*  label="Remember me"*/}
            {/*/>*/}
            <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Sign In
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link href="/forget-password" variant="body2">
                        Forgot password?
                    </Link>
                </Grid>
                <Grid item>
                    {loginType === 'applicant' && <Link href="/signup" variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>}
                    {loginType === 'recruiter' && <Link href="/recruiter/signup" variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>}
                </Grid>
            </Grid>
            {/*<Box mt={5}>*/}
            {/*    <Copyright/>*/}
            {/*</Box>*/}
        </form>)
}
