import {useEffect} from "react";
import {getRequest} from "../../Utils/fetch";
import {useAuthDispatch, useAuthState} from "../../Context";
import {deleteSession} from "../../Context/actions";

export default function CheckLoginStatus(props) {
    const dispatch = useAuthDispatch();
    const {isLoggedIn} = useAuthState();
    useEffect(async () => {
        if(isLoggedIn === false) return;
        const resp = await getRequest('/auth/user',{});
        if(resp.status !== 'success' || !resp.data) {
            return;
        }
        if(resp.data.isLoggedIn === false) await deleteSession(dispatch);
    },[]);
    return null;
}
