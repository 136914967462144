
export const JobCategoriesData = [
    {
        img__path:'/jobCategoriesImages/girl.svg',
        id: 3,
        name: 'Receptionist',
    },
    {
        img__path:'/jobCategoriesImages/finance.svg',
        id: 6,
        name: 'Accountant'
    },
    {
        img__path:'/jobCategoriesImages/fieldsales.svg',
        id: 2,
        name: 'Sales Executive'
    },
    {
        img__path:'/jobCategoriesImages/delivery-man.svg',
        id: 1,
        name: 'Delivery'
    },
    {
        img__path:'/jobCategoriesImages/counter.svg',
        id: 5,
        name: 'Counter Sales Job'
    },
    {
        img__path:'/jobCategoriesImages/laptop.svg',
        id: 7,
        name: 'Data Entry'
    },
    {
        img__path:'/jobCategoriesImages/support.svg',
        id: 0,
        name: 'Telecalling/BPO'
    },
    {
        img__path:'/jobCategoriesImages/technician.svg',
        id: 4,
        name: 'Technician'
    }
]
