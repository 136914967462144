import history from "../Utils/history";

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export async function loginUser(dispatch, loginPayload) {
    await logout(dispatch);
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(loginPayload),
    };

    try {
        dispatch({type: 'REQUEST_LOGIN'});
        let response = await fetch(`${ROOT_URL}/auth/login`, requestOptions);
        let resp = await response.json();
        const data = resp['data'];
        if (resp['status'] === 'success') {
            dispatch({type: 'LOGIN_SUCCESS', payload: data});
            localStorage.setItem('currentUser', JSON.stringify(data));
            return data;
        }

        dispatch({type: 'LOGIN_ERROR', error: resp.message});
        console.log(resp.message);
        return;
    } catch (error) {
        dispatch({type: 'LOGIN_ERROR', error: error.message});
        console.log(error);
    }
}

export async function manuelLoginUser(dispatch, data) {
    dispatch({type: 'LOGIN_SUCCESS', payload: data});
    localStorage.setItem('currentUser', JSON.stringify(data));
}

// export async function loginGoogle(dispatch, loginPayload) {
//     await logout(dispatch);
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         credentials: 'include',
//         body: JSON.stringify(loginPayload),
//     };
//
//     try {
//         dispatch({ type: 'REQUEST_LOGIN' });
//         let response = await fetch(`${ROOT_URL}/auth/google`, requestOptions);
//         let resp = await response.json();
//         const data = resp['data'];
//         if (resp['status'] === 'success') {
//             dispatch({ type: 'LOGIN_SUCCESS', payload: data });
//             localStorage.setItem('currentUser', JSON.stringify(data));
//             return data;
//         }
//
//         dispatch({ type: 'LOGIN_ERROR', error: resp.message });
//         console.log(resp.message);
//         return;
//     } catch (error) {
//         dispatch({ type: 'LOGIN_ERROR', error: error.message });
//         console.log(error);
//     }
// }
export async function loginGoogle(dispatch, loginPayload) {
    await logout(dispatch);
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(loginPayload),
    };
    try {
        let response = await fetch(`${ROOT_URL}/auth/googlelogin`, requestOptions);
        let resp = await response.json();
        if (resp.status !== 'success') {
            console.log(resp.message);
            return {};
        }
        if (resp.data.statusCode === 'selectAccountType') {
            console.log('selectAccountType');
            // setDialog({open: true, context: "login"});
            return {error: "selectAccountType", message: resp.message}
        } else if (resp.data.statusCode === 'noProfile') {
            console.log('noProfile');
            return {error: "noProfile", message: resp.message}
        } else if (resp.data.statusCode === 'userNotFound') {
            console.log('userNotFound');
            return {error: "userNotFound", message: resp.message}
        } else if (resp.data.statusCode === 'loginSuccess') {
            await manuelLoginUser(dispatch, resp.data);
            if (resp.data.user.profileType === 'applicant') history.push('/findjobs');
            else if (resp.data.user.profileType === 'recruiter') history.push('/recruiter/');
            return {}
        }

    } catch (error) {
        console.log(error);
    }
    return {}
}

export async function signUpApplicant(dispatch, registerPayload) {
    return await signUpUser(dispatch, registerPayload, '/auth/applicantSignup')
}

export async function signUpRecruiter(dispatch, registerPayload) {
    return await signUpUser(dispatch, registerPayload, '/auth/recruiterSignup');
}

async function signUpUser(dispatch, registerPayload, url) {
    await logout(dispatch);
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(registerPayload),
    };

    try {
        dispatch({type: 'REQUEST_SIGNUP'});
        let response = await fetch(`${ROOT_URL}${url}`, requestOptions);
        let resp = await response.json();
        const data = resp['data'];
        if (resp['status'] === 'success') {
            dispatch({type: 'SIGNUP_SUCCESS', payload: data});
            return data;
        }

        dispatch({type: 'SIGNUP_ERROR', error: resp.message});
        console.log(resp.message);
        return;
    } catch (error) {
        dispatch({type: 'SIGNUP_ERROR', error: error.message});
        console.log(error);
    }
}

export async function forgetPassword(dispatch, forgetPasswordPayload) {
    await logout(dispatch);
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(forgetPasswordPayload),
    };
    try {
        dispatch({type: 'REQUEST_FORGETPASS'});
        let response = await fetch(`${ROOT_URL}/auth/forgetpassword`, requestOptions);
        let resp = await response.json();
        const data = resp['data'];
        if (resp['status'] === 'success') {
            dispatch({type: 'FORGETPASS_SUCCESS', payload: data});
            return data;
        }

        dispatch({type: 'FORGETPASS_ERROR', error: resp.message});
        console.log(resp.message);
        return;
    } catch (error) {
        dispatch({type: 'FORGETPASS_ERROR', error: error.message});
        console.log(error);
    }
}

export async function logout(dispatch) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    };
    try {
        let response = await fetch(`${ROOT_URL}/auth/logout`, requestOptions);
        let resp = await response.json();
        const data = resp['data'];
        if (resp['status'] === 'success') {
            localStorage.removeItem('currentUser');
            dispatch({type: 'LOGOUT'});
            return true;
        }
        console.log(resp.message);
    } catch (error) {
        console.log(error);
    }
    return false;
}

export async function deleteSession(dispatch) {
    localStorage.removeItem('currentUser');
    dispatch({type: 'LOGOUT'});
}
