import React, { useState } from 'react';
import SignIn from "../../Components/SignIn/index.";
import SignInForm from "../../Components/SignIn/SignInForm";

function Login(props) {
    return (
        <SignIn heading={"Sign In"}>
            <SignInForm/>
        </SignIn>
    );
}

export default Login;
