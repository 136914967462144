import {Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {MdEmail} from 'react-icons/md'
import {postRequest} from "../../Utils/fetch";
import {useSnackbar} from "material-ui-snackbar-provider";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "80%",
        padding: theme.spacing(3),
        margin: "15px auto"
    },
    warning: {
        color: '#dc3545'
    }
}));
export default function VerificationStatus({data, ...props}) {
    const classes = useStyles();
    const isEmailVerified = data;
    const snackbar = useSnackbar();
    const resendEmailClicked = async () => {
        const resp = await postRequest('/auth/send-verification-email');
        if(resp['status'] !== 'success') {
            return snackbar.showMessage(resp['message'] || "Some Error Occurred")
        }
        snackbar.showMessage(resp['message']);
    }
    return (
        <>
            {isEmailVerified === false &&
            <Paper className={classes.paper}>
                <MdEmail style={{fontSize: '1.5em', verticalAlign: 'middle', marginRight: '6px'}}
                         className={classes.warning}/><h4 style={{display: 'inline'}} className={classes.warning}>Please
                check your email and click on verification link we've sent you. <a href="#" onClick={resendEmailClicked}>Resend</a></h4>
            </Paper>
            }
        </>
    )
}
