import React, {useState, useReducer} from 'react';

let user = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).user
    : '';

export const initialState = {
    user: '' || user,
    loading: false,
    errorMessage: null,
    isLoggedIn: (user && user.email && user.email !== '')? true: false,
};

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case 'REQUEST_LOGIN':
            return {
                ...initialState,
                loading: true,
                isLoggedIn: false,
            };
        case 'REQUEST_SIGNUP':
            return {
                ...initialState,
                loading: true,
                isLoggedIn: false,
            }
        case 'REQUEST_FORGETPASS':
            return {
                ...initialState,
                loading: true
            }
        case 'LOGIN_SUCCESS':
            return {
                ...initialState,
                user: action.payload.user,
                loading: false,
                errorMessage: false,
                isLoggedIn: true,
            };
        case 'SIGNUP_SUCCESS':
            return {
                ...initialState,
                loading: false,
                errorMessage: false,
            };
        case 'FORGETPASS_SUCCESS':
            return {
                ...initialState,
                loading: false,
                errorMessage: false,
            };
        case 'LOGOUT':
            return {
                ...initialState,
                user: '',
                isLoggedIn: false,
            };

        case 'LOGIN_ERROR':
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error,
            };
        case 'SIGNUP_ERROR':
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error,
            };
        case 'FORGETPASS_ERROR':
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error,
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
