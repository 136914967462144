import React, {useState} from 'react'
import {Button} from './Button'
import {Link} from 'react-router-dom'
import './Navbar.css'
import Dropdown from './Dropdown'
import logo from './logo_job.png'
import {useAuthState} from "../../Context";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {ClickAwayListener, Grow, IconButton, Menu, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import HireNowDialog from "../HireNowDialog";

const useStyles = makeStyles((theme) => ({
    accountIcon: {
        fontSize: '40px',
        color: 'RGB(25,118,210)',
    },
    accountDropdown: {
        zIndex: '100'
    }
}))

function Navbar() {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const [openHireNow, setOpenHireNow] = React.useState(false);

    const {isLoggedIn, user} = useAuthState();
    const classes = useStyles();
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(true);
        } else {
            setDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };

    //account menu pop
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);
    const accountMenu = <div>
        <IconButton aria-label="delete" className='nav-btn' ref={anchorRef} onClick={handleToggle}>
            <AccountCircleIcon className={classes.accountIcon}/>
        </IconButton>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                className={classes.accountDropdown}>
            {({TransitionProps, placement}) => (
                <Grow
                    {...TransitionProps}
                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem onClick={handleClose} component={Link} to="/profile">Profile</MenuItem>
                                {/*<MenuItem onClick={handleClose}>My account</MenuItem>*/}
                                <MenuItem onClick={handleClose} component={Link} to="/logout">Logout</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>

    return (
        <>
            <nav className='navbar'>
                <Link to='/'>
                    <img src={logo} alt="My Niyukti" height='100px'/>
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>

                    {(isLoggedIn && user.profileType === 'recruiter') ?
                        <>
                            <li className='nav-item'>
                                <Link to='/recruiter/findcandidates' className='nav-links' onClick={closeMobileMenu}>
                                    Find Candidates
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/recruiter/posted-jobs' className='nav-links' onClick={closeMobileMenu}>
                                    Jobs
                                </Link>
                            </li>
                        </>
                        :
                        <>
                            <li className='nav-item'>
                                <Link to='/findjobs' className='nav-links' onClick={closeMobileMenu}>
                                    Find Job
                                </Link>
                            </li>
                            {/* <li className='nav-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                                <Link to='#' className='nav-links' onClick={closeMobileMenu}>
                                Create Resume
                                </Link>
                                {/* { dropdown && <Dropdown/>}
                                </li> */}
                            <li className='nav-item'>
                                <Link to='/training-services' className='nav-links' onClick={closeMobileMenu}>
                                    Services
                                </Link>
                            </li>
                        </>
                    }
                    <li className='nav-item nav-btn'>
                        <Button location='/recruiter/postjob' name='Post Free job' style={{width: '100%'}}/>
                    </li>

                    {isLoggedIn ?
                        accountMenu
                        :
                        <>
                            <li className='nav-item nav-btn'>
                                <Button location='/login' name='Login' style={{width: '100%'}}/>
                            </li>
                            {/*<li className='nav-item nav-btn'>*/}
                            {/*    <Button location='/signup' name='Register' style={{width: '100%'}}/>*/}
                            {/*</li>*/}
                            <li className='nav-item nav-btn'>
                                <Button location='/recruiter/login' name='Recruiter Login' style={{width: '100%'}}/>
                            </li>
                            {/*<Button location='/recruiter/login' name='Recruiter Login' style={{width: '100%'}}/>*/}
                        </>
                    }
                    <li className='nav-item nav-item-mobile'>
                        <Link to='/recruiter/postjob' className='nav-links-mobile' onClick={closeMobileMenu}>
                            Post free job
                        </Link>
                        {/*<span >Post a job</span>*/}
                    </li>
                    {isLoggedIn ?
                        <li className='nav-item nav-item-mobile'>
                            <Link to='/profile' className='nav-links-mobile' onClick={closeMobileMenu}>
                                Profile
                            </Link>
                        </li>
                        : ''
                    }
                    {!isLoggedIn ?
                        <>
                            <li className='nav-item nav-item-mobile'>
                                <Link to='/login' className='nav-links-mobile' onClick={closeMobileMenu}>
                                    Login
                                </Link>
                            </li>
                            <li className='nav-item nav-item-mobile'>
                                <Link to='/recruiter/login' className='nav-links-mobile' onClick={closeMobileMenu}>
                                    Recruiter Login
                                </Link>
                            </li>
                        </>
                        :
                        <li className='nav-item nav-item-mobile'>
                            <Link to='/logout' className='nav-links-mobile' onClick={closeMobileMenu}>
                                Logout
                            </Link>
                        </li>
                    }

                </ul>

            </nav>
        </>
    )
}

export default Navbar;
