import React from 'react'
import './Footer.css'
import { makeStyles } from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root:{

    },
    link:{
        color:"#DCD427",
        display:"block",
        marginBottom: "0.5rem",
        textDecorationLine: "initial",
        '&:hover':{
            textDecorationLine: "underline"
        }
    }
  }));

function Footer() {
    const classes = useStyles();

    return (
        <div className="footer-div">
            <Grid container className={classes.root} spacing={2} justify="center" alignItems="flex-start">
                <Grid item xs={12} md={12}>
                    <p>Find Jobs By Type</p>
                </Grid>
                <Grid item xs={6} md={4}>
                    <a href="/findjobs?category=Accountant" className={classes.link}>Accountant</a>
                    <a href="/findjobs?category=Back Office" className={classes.link}>Back Office</a>
                    <a href="/findjobs?category=Business Development" className={classes.link}>Business Development</a>
                    <a href="/findjobs?category=Computer Operator" className={classes.link}>Computer Operator</a>
                    <a href="/findjobs?category=Counsellor" className={classes.link}>Counsellor</a>
                    <a href="/findjobs?category=Counter Sales Job" className={classes.link}>Counter Sales Job</a>
                    <a href="/findjobs?category=Data Entry" className={classes.link}>Data Entry</a>
                    <a href="/findjobs?category=Delivery" className={classes.link}>Delivery</a>
                </Grid>
                <Grid item xs={6} md={4}>
                    <a href="/findjobs?category=Digital Marketing" className={classes.link}>Digital Marketing</a>
                    <a href="/findjobs?category=Finance" className={classes.link}>Finance</a>
                    <a href="/findjobs?category=Hardware Engineer" className={classes.link}>Hardware Engineer</a>
                    <a href="/findjobs?category=Hotel Management" className={classes.link}>Hotel Management</a>
                    <a href="/findjobs?category=Housekeeping" className={classes.link}>Housekeeping</a>
                    <a href="/findjobs?category=Human Resource" className={classes.link}>Human Resource</a>
                    <a href="/findjobs?category=Marketing" className={classes.link}>Marketing</a>
                    <a href="/findjobs?category=Receptionist" className={classes.link}>Receptionist</a>
                </Grid>
                <Grid item xs={12} md={4}>
                    <a href="/findjobs?category=Retail" className={classes.link}>Retail</a>
                    <a href="/findjobs?category=Sales" className={classes.link}>Sales</a>
                    <a href="/findjobs?category=Security Guard" className={classes.link}>Security Guard</a>
                    <a href="/findjobs?category=Technician" className={classes.link}>Technician</a>
                    <a href="/findjobs?category=Telecalling/BPO" className={classes.link}>Telecalling/BPO</a>
                    <a href="/findjobs?category=Trainer" className={classes.link}>Trainer</a>
                    <a href="/findjobs?category=Other" className={classes.link}>Other</a>
                </Grid>
                <Grid item xs={6} md={4}>
                    <p style={{marginBottom:"0.6rem"}}>Find Jobs By City</p>
                    <a href="/findjobs?city=Delhi" className={classes.link}>Delhi</a>
                    <a href="/findjobs?city=Mumbai" className={classes.link}>Mumbai</a>
                    <a href="/findjobs?city=Bengaluru" className={classes.link}>Bengaluru</a>
                    <a href="/findjobs?city=Pune" className={classes.link}>Pune</a>
                    <a href="/findjobs?city=Hyderabad" className={classes.link}>Hyderabad</a>
                    <a href="/findjobs?city=Kolkata" className={classes.link}>Kolkata</a>
                    <a href="/findjobs?city=Chennai" className={classes.link}>Chennai</a>
                    <a href="/findjobs?city=Jaipur" className={classes.link}>Jaipur</a>
                </Grid>
                <Grid item xs={6} md={4}>
                    <p style={{marginBottom:"0.6rem"}}>Find Jobs By Qualification</p>
                    <a href="/findjobs?education=1" className={classes.link}>10th or Below</a>
                    <a href="/findjobs?education=2" className={classes.link}>10+2 or Below</a>
                    <a href="/findjobs?education=3" className={classes.link}>Diploma</a>
                    <a href="/findjobs?education=4" className={classes.link}>Graduate</a>
                    <a href="/findjobs?education=5" className={classes.link}>Post Graduate</a>
                </Grid>
                <Grid item xs={6} md={4}>
                    <p style={{marginBottom:"0.6rem"}}>Find Jobs By Experience Level</p>
                    <a href="/findjobs?experience=0" className={classes.link}>Fresher</a>
                    <a href="/findjobs?experience=0.5" className={classes.link}>&lt; 6 months</a>
                    <a href="/findjobs?experience=1" className={classes.link}>1 year</a>
                    <a href="/findjobs?experience=2" className={classes.link}>2 year</a>
                    <a href="/findjobs?experience=3" className={classes.link}>3 year</a>
                    <a href="/findjobs?experience=4" className={classes.link}>4 year</a>
                    <a href="/findjobs?experience=5" className={classes.link}>5 year</a>
                    <a href="/findjobs?experience=6" className={classes.link}>&gt; 5 year</a>
                </Grid>
            </Grid>

            <p style={{marginTop:"1rem"}}>Copyright © 2021 My Niyukti</p>
        </div>
    )
}

export default Footer;
