import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Container, Paper, Typography, InputLabel} from "@material-ui/core";
import {getRequest} from "../../Utils/fetch";
import Loader from "../../Components/Loader";
import Button from "@material-ui/core/Button";
// import {useLocation} from "react-router-dom";
import {useParams} from "react-router-dom";
import {useSnackbar} from "material-ui-snackbar-provider";
import ApplyNowBtn from "./ApplyNowBtn";
import {useAuthState} from "../../Context";
import jobTypeOptions from "../../assets/jobTypes.json";
import educationOptions from "../../assets/educations.json";
import experienceOptions from "../../assets/experiences.json";

const useStyles = makeStyles((theme) => ({
    container: {
        // padding: theme.spacing(2),
    },
    paper: {
        width: "80%",
        padding: theme.spacing(3),
        margin: "15px auto"
    },
    applyBtn: {}
}));

function JobDescription(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [jobDesription, setJobDesription] = useState({});
    const {id: jobId} = useParams();
    const {user, isLoggedIn} = useAuthState();
    let isRecruiter = false;
    let userObjectId;
    if(isLoggedIn === true && user && user.profileType === 'recruiter') { isRecruiter = true; userObjectId = user._id}
    const snackbar = useSnackbar();
    useEffect(async () => {                                                    //get api request
        // let params = { "jobId": props.location.state.jobId };

        let resp = await getRequest('/jobs/jobs', {jobId});
        console.log(resp);
        if (resp.status === 'success') {
            setJobDesription(resp.data);
            setLoading(false);
        } else {
            snackbar.showMessage("Unable to retrive job");
        }

    }, [])

    let jobType, experience, education;
    if(jobDesription.jobType)jobType = jobDesription.jobType.toString();
    if(jobDesription.experience) experience = jobDesription.experience.toString();
    if(jobDesription.education) education = jobDesription.education.toString();
    for (const jobTypeOption of jobTypeOptions){
        if(jobTypeOption["ValueId"] === jobType){jobType = jobTypeOption["TextName"]; break;}
    }
    
    for (const experienceOption of experienceOptions){
        if(experienceOption["ValueId"] === experience){experience = experienceOption["TextName"]; break;}
    }

    for (const educationOption of educationOptions){
        if(educationOption["ValueId"] === education){education = educationOption["TextName"]; break;}
    }

    if (jobDesription.jobType === 1)jobType='Full Time'
    else jobType='Part Time'
    
    if(jobDesription.experience === 0) experience = '0 year'
    else  if(jobDesription.experience === 0.5) experience = 'Less than 6 months'
    else if(jobDesription.experience === 1) experience = '1 year'
    else if(jobDesription.experience === 2) experience = '2 years'
    else if(jobDesription.experience === 3) experience = '3 years'
    else if(jobDesription.experience === 4) experience = '4 years'
    else if(jobDesription.experience === 5) experience = '5 years'
    else experience = 'More than 5 years'

    if(jobDesription.education === 1) education= '10 or Below'
    else if(jobDesription.education === 2) education= '10+2 or Below'
    else if(jobDesription.education === 3) education= 'Graduate'
    else education = 'Post Graduate'

    return (
        <React.Fragment>
            <Container maxWidth="md" className={classes.container}>
                <Loader loading={loading}/>
                {!loading && <>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2} justify="center">
                            <Grid item md={6} xs={12}>
                                <InputLabel id="title-label">Title:</InputLabel>
                                <Typography variant="h4" gutterBottom component="div"
                                            style={{fontFamily: "Poppins", fontWeight: "Bold"}}>
                                    {jobDesription.title}
                                </Typography>
                                <InputLabel id="description-label">Description:</InputLabel>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    {jobDesription.description}
                                </Typography>
                            </Grid>
                            <Grid item md={2} xs={12}>
                                {!isRecruiter && <ApplyNowBtn jobId={jobDesription._id}></ApplyNowBtn>}

                                <p style={{fontSize: "0.7rem", marginTop: "1rem"}}>
                                    Posted on: {jobDesription.date.substr(0, 10)}
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2} justify="center">
                            <Grid item md={8} xs={12}>
                                <Typography variant="h5" gutterBottom component="div" style={{fontFamily: "Poppins"}}>
                                    Job Details:
                                </Typography>
                            </Grid>
                            <Grid item md={8} xs={12}>


                                <InputLabel id="company-label">Company:</InputLabel>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    {jobDesription.company}
                                </Typography>
                                {/*<InputLabel id="recruiter-label">Recruiter:</InputLabel>*/}
                                {/*<Typography variant="subtitle1" gutterBottom component="div">*/}
                                {/*    {jobDesription.recruiter}*/}
                                {/*</Typography>*/}
                                <InputLabel id="salary-label">Salary:</InputLabel>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    <i className="fas fa-rupee-sign"></i>{jobDesription.minsalary} - <i
                                    className="fas fa-rupee-sign"></i>{jobDesription.maxsalary}
                                </Typography>
                                <InputLabel id="category-label">Category:</InputLabel>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    {jobDesription.category}
                                </Typography>
                                <InputLabel id="jobType-label">Job Type:</InputLabel>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    {jobType}
                                </Typography>
                                <InputLabel id="education-label">Education:</InputLabel>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    {education}
                                </Typography>
                                <InputLabel id="experience-label">Experience:</InputLabel>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    {experience}
                                </Typography>
                                <InputLabel id="city-label">City:</InputLabel>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    {jobDesription.city.toString()}
                                </Typography>
                                <InputLabel id="skills-label">Skills:</InputLabel>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    {jobDesription && jobDesription.skills && jobDesription.skills.toString()}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Paper>
                </>
                }
            </Container>

        </React.Fragment>
    )
}

export default JobDescription
