import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Loader from "../../Components/Loader";
import {Button, Container, Grid} from "@material-ui/core";
import {getRequest} from "../../Utils/fetch";
import {useSnackbar} from "material-ui-snackbar-provider";
import ProfileCard from "../../Components/ProfileCard";
import FilterDialogWrapper from "../FindCandidates/FilterDialogWrapper";
import zipcelx from "zipcelx";
import SendEmailModal from "../../Components/ProfileCard/SendEmailModal";
import FindCandidates from "../FindCandidates";
export default function ListAppliedCandidates() {
    const jobId = useParams().jobId;
    return (
        <FindCandidates incomingURL={'/jobs/jobapplication'} jobId={jobId}/>
    )
    // const jobId = useParams().jobId;
    // const [loading, setLoading] = useState(true);
    // const snackbar = useSnackbar();
    // const [users,setUsers] = useState([]);
    // let [selectedCandidates, setSelectedCandidates] = useState(new Set());
    // useEffect(async () => {
    //     const resp = await getRequest('/jobs/jobapplication',{recruiter: 1, jobId: jobId});
    //     if(!resp['status'] || resp['status'] !== 'success') {
    //         return snackbar.showMessage(resp['message'] || 'Some Error Occurred');
    //     }
    //     setUsers(resp['data'] || []);
    //     setLoading(false);
    // },[])
    // const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
    // const [sendEmailModalUserIds, setSendEmailModalUserIds] = useState([]);
    // const openEmailModal = (userIds) => {
    //     setSendEmailModalUserIds(userIds);
    //     setSendEmailModalOpen(true);
    // }
    // const downloadResume = async (user) => {
    //     try {
    //         const resp = await fetch(process.env.REACT_APP_ROOT_URL + "/applicants/resume?applicantId=" + user._id, {credentials: 'include'});
    //         if (!resp.ok) {
    //             const json = await resp.json();
    //             snackbar.showMessage(json['message']);
    //             return;
    //         }
    //         const blob = await resp.blob();
    //         let url = window.URL.createObjectURL(blob);
    //         let a = document.createElement('a');
    //         a.href = url;
    //         a.download = "Resume_" + user.name;
    //         document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    //         a.click();
    //         a.remove();  //afterwards we remove the element again
    //     } catch (err) {
    //         snackbar.showMessage("Unable to Download");
    //     }
    // }
    // const onClickBulkEmail = () => {
    //     if (selectedCandidates.size === 0) return snackbar.showMessage("No Candidates Selected");
    //     openEmailModal([...selectedCandidates]);
    // }
    // const onClickDownloadExcel = async () => {
    //     if (selectedCandidates.size === 0) return snackbar.showMessage("No Candidates Selected");
    //     const data = [
    //         ["Name", "Email", "Mobile", "Whatsapp", "Data of Birth", "Gender", "City", "Locality", "Education", "Skills", "Experience"].map((field) => ({
    //             value: field,
    //             type: 'string'
    //         }))
    //     ];
    //     for (let user of selectedCandidates) {
    //         const resp = await getRequest('/applicants/profiles', {applicantId: user._id,});
    //         if (resp['status'] !== 'success' || !resp['data']) {
    //             snackbar.showMessage("Some Error Occurred");
    //             return;
    //         }
    //         const userData = [
    //             resp['data'].name,
    //             resp['data'].email,
    //             resp['data'].mobile,
    //             resp['data'].whatsapp,
    //             resp['data'].dob,
    //             resp['data'].gender,
    //             resp['data'].city,
    //             resp['data'].locality,
    //             resp['data'].education ? (resp['data'].education.map((item) => (item.institute + " " + item.program + " " + item.course + " " + item.passingYear))).toString() : '',
    //             resp['data'].skills ? resp['data'].skills.toString() : "",
    //             resp['data'].experience ? (resp['data'].experience.map((item) => ([item.company, item.role, item.duration].join(' '))).toString()) : '',
    //         ].map((item) => ({value: item, type: 'string'}))
    //         data.push(userData);
    //     }
    //     console.log(data);
    //     const config = {
    //         filename: "MyNiyukti_Candidates",
    //         sheet: {
    //             data: data
    //         }
    //     }
    //     try {
    //         zipcelx(config);
    //     } catch (err) {
    //         console.log(err);
    //         snackbar.showMessage("Error Creating Excel file");
    //         return;
    //     }
    //
    // }
    // return (
    //     <>
    //         <SendEmailModal ids={sendEmailModalUserIds} open={sendEmailModalOpen} setOpen={setSendEmailModalOpen}/>
    //         <Container>
    //             <Grid container justify={"center"} alignItems="center">
    //                 <Grid item md={7} xs={11}>
    //                     <Button variant="outlined" onClick={onClickBulkEmail}>Bulk Email</Button>
    //                     <Button variant="outlined" onClick={onClickDownloadExcel}>Excel</Button>
    //                 </Grid>
    //             </Grid>
    //             {users.map((user,index) => {
    //                 return <ProfileCard key={index} user={user} sendEmailModal={openEmailModal} onSelect={() => {
    //                         selectedCandidates.add(user);
    //                         console.log(selectedCandidates)
    //                     }} onDeSelect={() => {
    //                     selectedCandidates.delete(user)
    //                 }} onDownloadResume={() => downloadResume(user)}/>
    //             })}
    //             <Loader loading={loading} />
    //         </Container>
    //     </>
    // )
}
