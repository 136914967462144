import {Container, Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    initialText: {
        fontWeight: "bold",
        marginTop: "10px",
        marginBottom: "20px"
    },
    heading: {
      textAlign: "center",
        margin: "20px"
    },
    list: {
        listStylePosition: "inside",
        margin: "10px",
        "& li": {
            lineHeight: 1.5
        }
    }
}))

export default function Terms() {
    const classes = useStyles();
    return (
        <div>
            <Container>
                <h1 className={classes.heading}>Terms and Conditions</h1>
                <Divider/>
                <div>
                    <div className={classes.initialText}>
                        You must take the time to read and understand the Terms and Conditions before registering for
                        our
                        services. By registering, you accept that you are entering into a contract with us based on
                        these
                        Terms and Conditions.<br/>
                        Visitors to MyNiyukti.com who do not register to become a Member (Employer or Employee)
                        similarly
                        affirm that they are bound by these Terms and Conditions each time they access the MyNiyukti.com
                        website.<br/>
                        If you do not accept the Terms and Conditions stated below, please refrain from using
                        MyNiyukti.com.
                    </div>
                    <ol className={classes.list}>
                        <li>
                            The use of the Website by a Employer or Employee shall be deemed acceptance of and
                            agreement
                            to these terms only.
                        </li>
                        <li>
                            The Website has been established to allow Employer to contact Employees and to view their
                            profiles detailing a Employee's experience. We do not issue any experience certificate to
                            our
                            registered members.
                        </li>
                        <li>
                            Any profile created showing political or illegal material would not be accepted under any
                            circumstances.
                        </li>
                        <li>
                            MyNiyukti.com will take all reasonable precautions to keep the details of Employers and
                            Employees secure but will not be liable for unauthorized access to the information provided
                            by
                            any party whatsoever.
                        </li>
                        <li>
                            The Members warrant that their e-mail and other contact addresses are valid and up to date
                            when using the Website.
                        </li>
                        <li>
                            Members agree not to impersonate any other person or entity or to use a false name or a
                            name
                            that they have no authority to use.
                        </li>
                        <li>
                            Members acknowledge that MyNiyukti.com is not liable for any form of loss or damage that
                            may
                            be suffered by a Member through the use of the Website including loss of data or information
                            or
                            any kind of financial or physical loss or damage.
                        </li>
                        <li>
                            MyNiyukti.com privacy policy forms part of these Terms and Conditions, and by agreeing to
                            these Terms and Conditions, you also give your consent to the manner in which we may handle
                            your
                            personal data as detailed in that policy.
                        </li>
                        <li>
                            The management reserves the right to modify the Terms and Conditions at any time without
                            any
                            prior notification.
                        </li>
                        <li>
                            These Terms will be subject to Indian Law and the jurisdiction of Indian Courts.
                        </li>
                        <li>
                            We do not cater Placement Agencies and consultancies. Any payments made by Placement
                            Agencies/ Consultancies will not be refunded under any situation.
                        </li>
                    </ol>
                </div>
            </Container>
        </div>
    )
}
