import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  useMediaQuery,
  Slider,
  Typography, Button, ButtonGroup, FormGroup, FormControlLabel, Checkbox
} from "@material-ui/core";
import TabPanelFilter from "../../Components/FilterDialog/TabPanelFilter";
import FilterOptions from "../../Components/FilterDialog/FilterOptions";
import FilterDialog from "../../Components/FilterDialog/FilterDialog";
import cityList from '../../assets/CitiesList.json';
import educationOptions from "../../assets/educations.json";
import experienceOptions from "../../assets/experiences.json";
import jobCategoriesOptions from "../../assets/jobCategories.json"

function a11yProps(index) {
  return {
    id: `vertical-tab-filter-${index}`,
    "aria-controls": `vertical-tabpanel-filter-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  salarySlider: {
    width: "40vw"
  },
}));

const genderOptions = [{ "TextName": "Male", "ValueId": "male" }, { "TextName": "Female", "ValueId": "female" }, { "TextName": "Other", "ValueId": "other" }];
const cityOptions = cityList.map((city) => {
  return {TextName: city.TextName, ValueId: city.TextName};
})

export default function FilterDialogWrapper(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, setSelectedValueFilter } = props;
  const [jobCategories, setJobCategories] = React.useState(selectedValue.jobCategories);
  const [gender, setGender] = React.useState(selectedValue.gender);
  const [experience, setExperience] = React.useState(selectedValue.experience);
  const [city, setCity] = React.useState(selectedValue.city);
  let tempCity = selectedValue.city;

  const [education, setEducation] = React.useState(selectedValue.education);
  const [salary, setSalary] = React.useState(selectedValue.salary);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const theme = useTheme();
  
  const changeJobCategories = (value) => {
    setJobCategories(value);
  }

  const changeGender = (value) => {
    setGender(value);
  }
  const changeExperience = (value) => {
    setExperience(value);
  }
  const changeCity = (value) => {
    setCity(value);
    tempCity = value;
  }
  const changeEducation = (event) => {
    setEducation({ ...education, [event.target.name]: event.target.checked });
  }
  const changeSalary = (event, newValue) => {
    setSalary(newValue);
  }

  const setFilterOption = () => {
    console.log(jobCategories);
    setSelectedValueFilter({ jobCategories, gender, city, education, salary, experience });
    // setRedirect(true);
    handleClose();
  }

  return (
    <FilterDialog onClose={onClose} selectedValue={selectedValue} open={open} setFilterOption={setFilterOption}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs filter"
        className={classes.tabs}
      >
        <Tab label="Job Categories" {...a11yProps(0)} />
        <Tab label="Gender" {...a11yProps(1)} />
        <Tab label="City" {...a11yProps(2)} />
        <Tab label="Education" {...a11yProps(3)} />
        <Tab label="Salary" {...a11yProps(4)} />
        <Tab label="Experience" {...a11yProps(5)} />
      </Tabs>

      <TabPanelFilter value={value} index={0}>
        <FilterOptions option={jobCategoriesOptions} handleChange={changeJobCategories} value={jobCategories}></FilterOptions>
      </TabPanelFilter>
      <TabPanelFilter value={value} index={1}>
        <FilterOptions option={genderOptions} handleChange={changeGender} value={gender}></FilterOptions>
      </TabPanelFilter>
      {/* <TabPanelFilter value={value} index={1}>
          <FilterOptions option={experienceOptions} handleChange={changeExperience} value={experience}></FilterOptions>
        </TabPanelFilter> */}
      <TabPanelFilter value={value} index={2}>
        <FilterOptions option={cityOptions} handleChange={changeCity} value={tempCity}></FilterOptions>
      </TabPanelFilter>
      <TabPanelFilter value={value} index={3}>
        {/* <FilterOptions option={educationOptions} handleChange={changeEducation} value={education}></FilterOptions> */}
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={education["1"]} onChange={changeEducation} name="1" />}
            label="10th or Below"
          />
          <FormControlLabel
            control={<Checkbox checked={education["2"]} onChange={changeEducation} name="2" />}
            label="10+2 or Below"
          />
          <FormControlLabel
              control={<Checkbox checked={education["3"]} onChange={changeEducation} name="3" />}
              label="Diploma"
          />
          <FormControlLabel
            control={<Checkbox checked={education["4"]} onChange={changeEducation} name="4" />}
            label="Graduate"
          />
          <FormControlLabel
            control={<Checkbox checked={education["5"]} onChange={changeEducation} name="5" />}
            label="Post Graduate"
          />
        </FormGroup>
      </TabPanelFilter>
      <TabPanelFilter value={value} index={4}>
        <div className={classes.salarySlider}>
          <Typography id="range-slider-salary" gutterBottom>
            Salary
          </Typography>
          <Slider
            value={salary}
            onChange={changeSalary}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider-salary"
            getAriaValueText={salarytext}
            marks={[
              {
                value: 0,
                label: 'Rs. 0',
              },
              {
                value: 100,
                label: 'Rs. 100000',
              },
            ]}
          />
          <Typography gutterBottom>
            Rs. {salary[0] * 1000} to Rs. {salary[1] * 1000}
          </Typography>
        </div>
      </TabPanelFilter>
      <TabPanelFilter value={value} index={5}>
        <FilterOptions option={experienceOptions} handleChange={changeExperience} value={experience}></FilterOptions>
      </TabPanelFilter>
    </FilterDialog>
  )

  function salarytext(value) {
    return `${value}k`;
  }
}

