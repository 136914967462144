import React, {useState} from "react";
import Box from './Box';
import {Button, FormControl, MenuItem, Select, Typography} from "@material-ui/core";
import FormField from "./FormField";
import {makeStyles} from "@material-ui/core";
import {useForm} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import CitiesList from "../../assets/CitiesList.json";
import {Autocomplete} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    pickers: {},
    editFormButtons: {
        margin: theme.spacing(1)
    },
    error: {
        color: "red",
    }
}))


export default function RecruiterProfileBox(props) {
    const {data: finalDetails, setFinalData: setFinalDetails} = props;
    const [editMode, setEditMode] = useState(false);
    const classes = useStyles();

    const {register, handleSubmit, errors, control} = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    // const [finalDetails, setFinalDetails] = useState({
    //     "name": "Prashant Shakya",
    //     "email": "prashushakya@gmail.com",
    //     "mobile": "9005902201",
    //     "dob": "Fri Apr 5 1998 00:00:00 GMT+0530 (India Standard Time)",
    //     "gender": "male",
    //     "location": "Sitapur",
    //     "resume": "Resume.pdf"
    // });
    // const [fileInput, setFileInput] = useState();
    const [details, setDetails] = useState(finalDetails);
    const handleDetailsChange = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value,
        })
    };

    const handleCityChange = (e, option) => {
        setDetails({
            ...details,
            city: (option && option.TextName)?option.TextName: null,
        })
    }
    // const resumeSelectedHandler = (e) => {
    //     console.log(e.target.files);
    //     setDetails({
    //         ...details,
    //         'resume': e.target.files[0]
    //     })
    // }
    const fields = {
        "Name": [
            <input type="text" placeholder="Enter Your Name" value={details["name"]} name="name"
                   onChange={handleDetailsChange}/>,
            <Typography>{finalDetails["name"]}</Typography>
        ],
        "Email": [
            <input type="text" placeholder="Enter Your Personal Email" value={details["email"]}
                   onChange={handleDetailsChange} name="email" disabled/>,
            <Typography>{finalDetails["email"]}</Typography>
        ],
        "Company": [
            <input type="text" placeholder="Enter Your Company Name" value={details["company"]} name="company"
                   onChange={handleDetailsChange}/>,
            <Typography>{finalDetails["company"]}</Typography>
        ],
        "Contact": [
            <>
                <input type="text" placeholder="Enter Your Contact No" onChange={handleDetailsChange}
                       value={details["contact"]}
                       name="contact" ref={register({
                    // required: 'You must provide a 10 digit mobile number!',
                    pattern: {
                        value: /^\d{10}$/,
                        message: 'You must provide a valid 10 digit contact number',
                    },
                })}
                       error={!!errors.contact}/>
                {errors.contact && (<span className={classes.error}>{errors.contact.message}</span>)}
            </>,
            <Typography>{finalDetails && finalDetails.contact}</Typography>
        ],
        "City": [
            <div>
                <Autocomplete
                    disablePortal
                    className={classes.selectCity}
                    options={CitiesList}
                    fullWidth
                    value={{TextName: details["city"]}}
                    getOptionLabel={(option) => option.TextName}
                    onChange={handleCityChange}
                    renderInput={(params) => <TextField variant="standard" {...params}/>}
                /></div>,
            <Typography>{finalDetails["city"]}</Typography>
        ],
        "Address": [
            <input type="text" placeholder="Enter Your Address" value={details["address"]}
                   onChange={handleDetailsChange} name="address"/>,
            <Typography>{finalDetails["address"]}</Typography>
        ],
    }
    const onClickSave = () => {
        console.log(details);
        setFinalDetails('personal', details);
        setEditMode(false);
    }
    const onSubmit = async (data) => {
        setFinalDetails('personal', details);
        setEditMode(false);
    };
    const onClickEdit = () => {
        setEditMode(!editMode);
        setDetails(finalDetails);
    };

    const editFormButtons =
        <div>
            <Button variant="contained" color="primary" className={classes.editFormButtons}
                    type={"submit"}>Save</Button>
            <Button variant="contained" color="default" className={classes.editFormButtons}
                    onClick={onClickEdit}>Cancel</Button>
        </div>

    return (
        <Box heading={"Personal"} setEditMode={setEditMode} editMode={editMode} onClickEdit={onClickEdit}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    {Object.keys(fields).map((field, index) => (
                        <FormField key={index} field={field} formElement={fields[field][0]} value={fields[field][1]}
                                   editMode={editMode} required/>
                    ))}
                    <FormField field="" formElement={editFormButtons} value={""} editMode={editMode}/>
                </form>
        </Box>
    )
}
