import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {getRequest, postRequest} from "../../Utils/fetch";
import {useSnackbar} from "material-ui-snackbar-provider";
import Loader from "../../Components/Loader";
import {Container, Paper, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
function useQuery() {
    return new URLSearchParams(useLocation().search); //?orderrid=hsdalf&error=
}
export default function PaymentStatus(props) {
    const query = useQuery();
    console.log(query.get('orderId'),query.get('error'))
    const orderId = query.get('orderId');
    const error = query.get('error');
    const snackbar = useSnackbar();
    const [paymentInfo,setPaymentInfo] = useState({});
    const [loading,setLoading] = useState(true);
    useEffect(async () => {
        const resp = await postRequest('/payment/status',{orderId});
        if(resp['status'] !== 'success') {
            return snackbar.showMessage(resp['message']);
        }
        setPaymentInfo(resp['data']);
        setLoading(false);
        console.log(resp['data']);
    },[])
    return (
        <>
            <Loader loading={loading}/>
            {!loading && <Container maxWidth={"md"}>
                <Paper>
                    <Grid container justify={"center"} style={{height: '60vh',margin: '20px auto'}}>
                        <Grid container item xs={11} md={7}>
                            <Grid xs={12} item><h2 style={{textAlign: "center"}}>Payment Details</h2></Grid>
                            <Grid item xs={6}><h4>Status:</h4> </Grid><Grid item xs={6}>{paymentInfo.status === 's'? 'Success':'Fail'}</Grid>
                            <Grid item xs={6}><h4>Bank Name:</h4> </Grid><Grid item xs={6}>{paymentInfo.bankName}</Grid>
                            <Grid item xs={6}><h4>Transaction Id:</h4> </Grid><Grid item xs={6}>{paymentInfo.bankTransactionId}</Grid>
                            <Grid item xs={6}><h4>Order Id:</h4></Grid><Grid item xs={6}>{paymentInfo.orderId}</Grid>
                            <Grid item xs={6}><h4>Amount:</h4> </Grid><Grid item xs={6}>{paymentInfo.amount}</Grid>
                            <Grid item xs={6}><h4>Date:</h4> </Grid><Grid item xs={6}>{paymentInfo.date ? (new Date(paymentInfo.date)).toLocaleString('en-IN'):''}</Grid>
                            <Grid item xs={6}><h4>Gateway Name:</h4> </Grid><Grid item xs={6}>{paymentInfo.gatewayName}</Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>}
        </>
    )
}
