// import React from 'react';
import Login from '../Pages/Login';
import NotFound from '../Pages/NotFound';
import Home from '../Pages/Home';
import EditProfile from "../Pages/EditProfile";
import SignUp from "../Pages/SignUp"
import ForgetPassword from "../Pages/ForgetPassword";
import Logout from "../Pages/Logout/index.";
import FindJobs from '../Pages/FindJobs';
import PostJob from '../Pages/PostJob/PostJob';
import JobDescription from "../Pages/FindJobs/JobDescription";
import EmailVerification from "../Pages/EmailVerification";
import RecruiterAppRoutes from "../Components/RecruiterAppRoutes";
import TrainingServices from '../Pages/Services/TrainingServices/TrainingServices';
import PaymentStatus from "../Pages/PaymentStatus";
import ResetPassword from "../Pages/ResetPassword";
import Terms from "../Pages/Terms";

const routes = [
    {
        path: '/login',
        component: Login,
        isPrivate: false,
    },
    {
        path: '/signup',
        component: SignUp,
        isPrivate: false,
    },
    {
        path: '/logout',
        component: Logout,
        isPrivate: false
    },
    {
        path: '/profile',
        component: EditProfile,
        isPrivate: true
    },
    {
        path: '/findjobs/',
        component: FindJobs,
        isPrivate: false,
    },
    {
        path: '/email-verification/:id',
        component: EmailVerification,
        isPrivate: false
    },
    {
        path: '/jobs/:id',
        component: JobDescription,
        isPrivate: false,
    },
    {
        path: '/postjob',
        component: PostJob,
        isPrivate: true
    },
    {
        path: '/recruiter',
        component: RecruiterAppRoutes,
        isPrivate: false,
    },
    {
        path: '/forget-password',
        component: ForgetPassword,
        isPrivate: false,
    },
    {
        path: '/terms',
        component: Terms,
        isPrivate: false,
    },
    {
        path: '/reset-password/:token',
        component: ResetPassword,
        isPrivate: false,
    },
    {
        path: '/training-services',
        component: TrainingServices,
        isPrivate: false,
    },
    {
        path: '/payment/status',
        component: PaymentStatus,
        isPrivate: false,
    },
    {
        path: '/*',
        component: NotFound,
        isPrivate: false,
    },
];

export default routes;
