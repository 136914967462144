import React from 'react'
import './HeroSection.css'
import {Grid, Button, Link} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import HireNowDialog from "../../Components/HireNowDialog";

const useStyles = makeStyles((theme) => ({
    getJobAndHireNowBtn: {
        textAlign:"center",
        display:"block",
        width:"32%",
        margin:"auto",
        backgroundColor:"#3CB371",
        '&:hover': {
            backgroundColor:"#008000"
        },
        '@media screen and (max-width: 768px)' : {
            width: '80%'
          }
    }
}));

function HeroSection() {
    const classes = useStyles();

    return (
        <div className='home__hero-section darkBg'>
            <div className="container">
                {/*<div className="launching-line">My Niyukti</div>*/}
                {/*<div className="tag-line">Your Niyukti Our Responsiblity</div>*/}
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={6} md={6}>
                        <div className="img-employer">
                            <div className="home__hero-text-wrapper">
                                {/* <div className="top-line">We're launching soon!</div> */}
                                <Button variant="contained" color="primary" className={classes.getJobAndHireNowBtn} href={'/recruiter/postjob'}>Hire Now</Button>
                                <h1 className='heading'>Find your right fit</h1>
                                {/* <p className='home__hero-subtitle'>Create account and hire using our end to end staffing solution!</p> */}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <div className="img-employee">
                            <div className="home__hero-text-wrapper">
                                {/* <div className="top-line">We're launching soon!</div> */}
                                <Button variant="contained" color="primary" href="/findjobs" className={classes.getJobAndHireNowBtn}>Get Job</Button>
                                <h1 className='heading'>Find your job faster</h1>
                                {/* <p className='home__hero-subtitle'>Create free account to find thousand Jobs, Employment & Career Opportunities around you!</p> */}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

        </div>
    )
}

export default HeroSection;
