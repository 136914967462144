import React, {useEffect, useState} from "react";
import {
    Box,
    Container,
    Grid,
    Paper,
    Typography,
    TextField, InputAdornment, Chip, Button, Checkbox,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import './PostJob.css';
import CitiesList from "../../assets/CitiesList.json"
import {useForm} from "react-hook-form";
import jobCategories from '../../assets/jobCategories.json'
import {signUpUser} from "../../Context/actions";
import {getRequest, postRequest, putRequest} from "../../Utils/fetch";
import {Link, useLocation, useParams} from "react-router-dom";
import {useSnackbar} from "material-ui-snackbar-provider";
import history from "../../Utils/history";
import educations from './../../assets/educations.json';
import jobTypes from '../../assets/jobTypes.json';
import experiences from '../../assets/experiences.json'

const initialValues = {
    title: "",
    category: "",
    experience: "",
    jobType: "",
    city: "",
    minsalary: "",
    maxsalary: "",
    skills: [],
    jobDescription: "",
    company: "",
    companyContactName: "",
    companyContactPhone: "",
    companyEmail: "",
    companyJobAddress:""
};

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            // margin: theme.spacing(1),
            width: "100%",
            margin: "8px 0%",
        },
        "& .MuiTypography-h5": {
            fontFamily: ` "Montserrat", sans-serif`,
        },
        "& .MuiFilledInput-root": {
            backgroundColor: "#f6fafd",
        },
        "& > *": {
            fontFamily: `Montserrat', 'san-serif'`,
        },

    },
    postJob: {
        margin: "0.5em",
        fontFamily: "Poppins",
        borderBottom: "1px solid #eaeff5",
        color: "#0047AB"
    },
    error: {
        color: "red",
    },
    // formField: {
    //     margin: theme.spacing(2),
    // }
}));

function PostJob({data = {},mode = 'create', ...props}) {
    const classes = useStyles();
    const [skills,setSkills] = useState(data && data.skills ? data.skills: []);
    const [cities, setCities] = useState(data && data.city ? data.city.map(city => CitiesList.find(val => val.TextName === city)) : []);
    const defaultValues = {
        title: data && data.title ? data.title : '',
        category: data && data.category? jobCategories.find(val => val === data.category) : null,
        experience: data && data.experience? experiences.find(val => val['ValueId'] == data.experience) : null,
        jobType: data &&  data.jobType? jobTypes.find(val => val['ValueId'] == data.jobType) : null,
        education: data && data.education? educations.find(val => val['ValueId'] == data.education) : null,
        city: data && data.city ? data.city.map(city => CitiesList.find(val => val.TextName === city)) : [],
        minsalary: data ? data.minsalary : null,
        maxsalary: data ? data.maxsalary : null,
        skills: data && data.skills ? data.skills: [],
        description: data && data.description ? data.description: null,
        company: data && data.company ? data.company : null,
        companyContactName: data && data.companyContactName ? data.companyContactName : null,
        companyContactPhone: data && data.companyContactPhone ? data.companyContactPhone : null,
        companyEmail: data && data.companyEmail ? data.companyEmail : null,
        companyJobAddress:data && data.companyJobAddress ? data.companyJobAddress : null
    }
    console.log(defaultValues)
    const {register, handleSubmit, errors, setValue, control} = useForm({
        // mode: 'onChange',
        // reValidateMode: 'onChange',
        defaultValues: defaultValues,
    });
    useEffect(() => {
        register('title',{
            validate: (value) => {
                if(!value) return 'This is required.';
                return value.length || 'This is required.'
            },
        });
        register('category',{
            validate: (value) => {
                if(!value) return 'This is required.';
                return value.length || 'This is required.'
            },
        });
        register('experience',{
            validate: (value) => {
                if(!value || !value.TextName) return 'This is required.';
                return value.TextName.length || 'This is required.'
            },
        });
        register('jobType',{
            validate: (value) => {
                if(!value || !value.TextName) return 'This is required.';
                return value.TextName.length || 'This is required.'
            },
        });
        register('city',{
            validate: (value) => {
                if(!value || value.length === 0) return 'This is required.';
                return true;
            },
        })
        register('minsalary',{
            validate: (value) => {
                if(value == null || value === '') return 'This is required.';
                return true;
            },
        })
        register('maxsalary',{
            validate: (value) => {
                if(value == null || value === '') return 'This is required.';
                return true;
            },
        })
        register('education',{
            validate: (value) => {
                if(!value) return 'This is required.';
                return value.TextName.length || 'This is required.'
            },
        })
        // register('skills',{
        //     validate: (value) => {
        //         console.log(skills,value)
        //         if(value == null || value.length === 0 || value === '') return 'This is required.';
        //         return true;
        //     },
        // })
        register('description',{
            validate: (value) => {
                if(value == null || value === '') return 'This is required.';
                return true;
            },
        })
        register('company',{
            validate: (value) => {
                if(value == null || value === '') return 'This is required.';
                return true;
            },
        })
        register('companyContactName',{
            required: 'You must provide Contact Name!',
            pattern: {
                value: /[a-zA-Z][a-zA-Z ]{2,}/,
                message: 'You must provide a valid Name!',
            },
        });
        register('companyContactPhone',{
            required: 'You must provide a Company Mobile Number!',
            pattern: {
                value: /^[0-9]{10}$/,
                message: 'You must provide a valid 10 digit mobile number',
            }
        })
        register('companyEmail',{
            required: 'You must provide the email address!',
            pattern: {
                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'You must provide a valid email address!',
            },
        })
        register('companyJobAddress',{
            validate: (value) => {
                if(value == null || value === '') return 'This is required.';
                return true;
            },
        })
    },[register])

    const [newSkill,setNewSkill] = useState('');
    const [termsChecked, setTermsChecked] = useState(false)
    const snackbar = useSnackbar();
    const jobId = useParams().jobId;
    const onSubmit = async (data,e) => {
        console.log(data);
        const reqData = {
            title: data.title,
            category: data.category,
            experience: data.experience.ValueId,
            jobType: data.jobType.ValueId,
            city: data.city.map((obj) => obj.TextName),
            minsalary: data.minsalary,
            maxsalary: data.maxsalary,
            education: data.education.ValueId,
            skills: skills,
            description: data.description,
            company: data.company,
            companyContactName: data.companyContactName,
            companyContactPhone: data.companyContactPhone,
            companyEmail: data.companyEmail,
            companyJobAddress:data.companyJobAddress,
            jobId: jobId,
        }
        console.log(reqData);
        try {
            let response;
            if(mode === 'create') {
                response = await putRequest('/jobs/jobs', reqData);
            } else {
                response = await postRequest('/jobs/jobs', reqData);
            }
            if(response.status !== 'success') {
                snackbar.showMessage(response.message || 'Unable to Post this job');
                return;
            }
            snackbar.showMessage(response.message || "Job Posted Successfully");
            history.go(0);
            e.target.reset();
        } catch (error) {
            console.log(error);
            snackbar.showMessage("Some Error Occured");
        }
    }
    const addSkill = (skill) => {
        if(!skill) return;
        setSkills([...skills, skill])
        setValue('skills',[...skills])
    }
    const deleteSkill = (index) => {
        setSkills(skills.filter((_,i) => i!==index));
        setValue('skills',[...skills]);
    }
    return (
        <React.Fragment>
            <div className="post-job-bg">
                <Container>
                    <h3 className="post-job">Post Job</h3>
                </Container>
            </div>
            <Container maxWidth="md">
                <Paper elevation={4}>
                    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
                        <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            className={classes.postJob}
                        >
                            Basic Job Details
                        </Typography>
                        <Grid container direction="row" justify="space-evenly">
                            <Grid item xs={11} md={10}>
                                <TextField
                                    label="Job Title*"
                                    fullWidth
                                    name="title"
                                    defaultValue={defaultValues.title}
                                    error={!!errors.title}
                                    onChange={(e) => setValue('title',e.target.value)}
                                />
                                {errors.title && (
                                    <span className={classes.error}>{errors.title.message}</span>
                                )}
                            </Grid>
                            <Grid item xs={11} md={5}>
                                <Autocomplete
                                    getOptionLabel={(option) => option}
                                    fullWidth
                                    defaultValue={defaultValues.category}
                                    disabled={mode === 'update'}
                                    // getOptionSelected={(option, value) => option.TextName === value.TextName}
                                    onChange={(e,option) => setValue('category',option)}
                                    renderInput={(params) => <TextField {...params} error={!!errors.category} label="Job Category*"/>}
                                    options={jobCategories}
                                />
                                {errors.category && (<span className={classes.error}>{errors.category.message}</span>)}
                            </Grid>
                            <Grid item xs={11} md={4}>
                                <Autocomplete
                                    onChange={(e,option) => setValue('experience',option)}
                                    fullWidth
                                    id="select-experience-id"
                                    options={experiences}
                                    defaultValue={defaultValues.experience}
                                    getOptionLabel={(option) => option.TextName}
                                    renderInput={(params) => <TextField {...params} error={!!errors.experience} label="Experience*"/>}
                                />
                                {errors.experience && (
                                    <span className={classes.error}>{errors.experience.message}</span>
                                )}
                            </Grid>

                            <Grid item xs={11} md={5}>
                                <Autocomplete
                                    onChange={(e,option) => setValue('jobType',option)}
                                    fullWidth
                                    disablePortal
                                    disabled={mode === 'update'}
                                    id="select-job-type-id"
                                    options={jobTypes}
                                    defaultValue={defaultValues.jobType}
                                    getOptionLabel={(option) => option.TextName}
                                    renderInput={(params) => <TextField {...params} error={!!errors.jobType} label="Job Type*"/>}
                                />
                                {errors.jobType && (
                                    <span className={classes.error}>{errors.jobType.message}</span>
                                )}
                            </Grid>
                            <Grid item xs={11} md={4}>
                                <Autocomplete
                                    onChange={(e,option) => setValue('education',option)}
                                    fullWidth
                                    disablePortal
                                    options={educations}
                                    defaultValue={defaultValues.education}
                                    getOptionLabel={(option) => option.TextName}
                                    renderInput={(params) => <TextField {...params} error={!!errors.education} label="Education*"/>}
                                />
                                {errors.education && (
                                    <span className={classes.error}>{errors.education.message}</span>
                                )}
                            </Grid>

                            <Grid item xs={11} md={5}>

                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <TextField
                                            onChange={(e) => setValue('minsalary',e.target.value)}
                                            fullWidth
                                            id="input-min-salary"
                                            error={!!errors.minsalary}
                                            defaultValue={defaultValues.minsalary}
                                            type="number"
                                                   label="Min. Salary*"
                                                   InputProps={{
                                                       startAdornment: (
                                                           <InputAdornment position="start">
                                                               <i className="fas fa-rupee-sign"></i>
                                                           </InputAdornment>
                                                       ),
                                                   }}/>
                                        {errors.minsalary && (
                                            <span className={classes.error}>{errors.minsalary.message}</span>
                                        )}
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={11} md={4}>

                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <TextField
                                            onChange={(e) => setValue('maxsalary',e.target.value)}
                                            fullWidth
                                            id="input-max-salary"
                                            label="Max. Salary*"
                                            type="number"
                                            error={!!errors.maxsalary}
                                            defaultValue={defaultValues.maxsalary}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fas fa-rupee-sign"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {errors.maxsalary && (
                                            <span className={classes.error}>{errors.maxsalary.message}</span>
                                        )}
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={11} md={10}>
                                <Autocomplete
                                    multiple
                                    filterSelectedOptions
                                    getOptionDisabled={(options) => (cities.length >= 3)}
                                    onChange={(e,option) => {setValue('city',option);
                                        setCities(option)}}
                                    disabled={mode === 'update'}
                                    fullWidth
                                    id="select-city-id"
                                    options={CitiesList}
                                    defaultValue={defaultValues.city}
                                    getOptionLabel={(option) => option.TextName}
                                    renderInput={(params) => <TextField {...params} error={!!errors.city} label="City*"/>}
                                />
                                {errors.city && (
                                    <span className={classes.error}>{errors.city.message}</span>
                                )}
                            </Grid>
                            <Grid item xs={11} md={10}>
                                {skills.map((skill,index) => (
                                    <Chip
                                        key={skill}
                                        label={skill}
                                        color="primary"
                                        onDelete={() => deleteSkill(index)}
                                    />
                                ))}
                            </Grid>
                            <Grid item xs={11} md={10}>
                                <TextField
                                    fullWidth
                                    label="Skills"
                                    name="skills"
                                    value={newSkill}
                                    onKeyDown={(e) => {if(e.key === 'Enter') {e.preventDefault(); addSkill(newSkill); setNewSkill('')}}}
                                    onChange={(e) => setNewSkill(e.target.value)}
                                    placeholder="Press Enter to Add Skill"
                                    error={!!errors.skills}
                                />
                                {errors.skills && (
                                    <span className={classes.error}>{errors.skills.message}</span>
                                )}
                            </Grid>
                            <Grid item xs={11} md={10}>
                                <TextField
                                    onChange={(e) => setValue('description',e.target.value)}
                                    fullWidth
                                    label="Job Description*"
                                    name="description"
                                    defaultValue={defaultValues.description}
                                    error={!!errors.description}
                                    multiline
                                    rows={3}
                                    rowsMax={6}
                                />
                                {errors.description && (
                                    <span className={classes.error}>{errors.description.message}</span>
                                )}
                            </Grid>
                        </Grid>


                        <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            className={classes.postJob}
                        >
                            Company Details
                        </Typography>

                        <Grid container direction="row" justify="space-evenly" alignItems="center">
                            <Grid item xs={11} md={5}>
                                <TextField
                                    onChange={(e) => setValue('company',e.target.value)}
                                    fullWidth
                                    label="Company Name*"
                                    defaultValue={defaultValues.company}
                                    name="company"
                                    error={!!errors.company}
                                />
                                {errors.company && (
                                    <span className={classes.error}>{errors.company.message}</span>
                                )}
                            </Grid>
                            <Grid item xs={11} md={4}>
                                <TextField
                                    onChange={(e) => setValue('companyContactName',e.target.value)}
                                    fullWidth
                                    label="Contact Person Name*"
                                    defaultValue={defaultValues.companyContactName}
                                    name="companyContactName"
                                    error={!!errors.companyContactName}
                                />
                                {errors.companyContactName && (
                                    <span className={classes.error}>{errors.companyContactName.message}</span>
                                )}
                            </Grid>
                            <Grid item xs={11} md={5}>
                                <TextField
                                    onChange={(e) => setValue('companyContactPhone',e.target.value)}
                                    fullWidth
                                    label="Phone Number*"
                                    name="companyContactPhone"
                                    defaultValue={defaultValues.companyContactPhone}
                                    error={!!errors.companyContactPhone}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                    }}
                                />
                                {errors.companyContactPhone && (
                                    <span className={classes.error}>{errors.companyContactPhone.message}</span>
                                )}
                            </Grid>
                            <Grid item xs={11} md={4}>
                                <TextField
                                    onChange={(e) => setValue('companyEmail',e.target.value)}
                                    fullWidth
                                    label="Email Address*"
                                    defaultValue={defaultValues.companyEmail}
                                    name="companyEmail"
                                    error={!!errors.companyEmail}
                                />
                                {errors.companyEmail && (
                                    <span className={classes.error}>{errors.companyEmail.message}</span>
                                )}
                            </Grid>
                            <Grid item xs={11} md={10}>
                                <TextField
                                    onChange={(e) => setValue('companyJobAddress',e.target.value)}
                                    fullWidth
                                    label="Job Address*"
                                    name="companyJobAddress"
                                    defaultValue={defaultValues.companyJobAddress}
                                    error={!!errors.companyJobAddress}
                                    multiline
                                    rows={2}
                                    rowsMax={4}
                                />
                                {errors.companyJobAddress && (
                                    <span className={classes.error}>{errors.companyJobAddress.message}</span>
                                )}
                            </Grid>
                            <Grid xs={11} md={10}>
                                <Typography >
                                    <Checkbox color="primary" checked={termsChecked} onChange={(event) => setTermsChecked(event.target.checked)}/>
                                    I accept <Link to="/terms" target="_blank">Terms & Conditions</Link> and
                                    <Link to="#"> Privacy Policy</Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={11} md={6}><Button color="primary" variant="contained" fullWidth style={{margin: "10px"}} type="submit" disabled={!termsChecked}>Submit</Button></Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </React.Fragment>
    );
}

export default PostJob;
