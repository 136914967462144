import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuthState } from '../Context';

const AppRoutes = ({ component: Component, path, isPrivate, key, profileType = null,rootPath = '', ...rest }) => {
    const userDetails = useAuthState();
    return (
        <Route
            path={path}
            render={(props) =>
                isPrivate && userDetails && (userDetails.isLoggedIn !== true || (userDetails.user.profileType !== profileType && profileType != null))  ? (
                    <Redirect to={{ pathname: rootPath + '/login' }} />
                ) : (
                     <Component {...props} />
                )
            
            }
            {...rest}
        />
    );
};

export default AppRoutes;
