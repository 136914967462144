import styles from './GoogleSignInBtn.module.scss'

export default function GoogleSignInBtn(props) {
    const {onClick, btnText} = props;
    return (
        <div className={styles['google-btn']} onClick={onClick}>
            <div className={styles['google-icon-wrapper']}>
                <img className={styles['google-icon']}
                     src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
            </div>
            <p className={styles['btn-text']}><b>{btnText}</b></p>
        </div>
    )
}
