import ProfileCard from "../../Components/ProfileCard";
import {Button, Checkbox, Container, Grid, Hidden, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SendEmailModal from "../../Components/ProfileCard/SendEmailModal";
import React, {useState, useEffect, useRef, useCallback} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {getRequest} from "../../Utils/fetch";
import {useSnackbar} from "material-ui-snackbar-provider";
import Loader from "../../Components/Loader";
import FilterDialogWrapper from './FilterDialogWrapper';
import SortDialog from '../../Components/SortDialog/SortDialog';
import zipcelx from 'zipcelx';
import Pagination from '@material-ui/lab/Pagination';
import SideFilterBox from "./SideFilterBox";

const useStyles = makeStyles((theme) => ({}));


export default function FindCandidates({incomingURL = '/applicants/profiles', jobId, ...props}) {
    const classes = useStyles();
    const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
    const [sendEmailModalUserIds, setSendEmailModalUserIds] = useState([]);
    const openEmailModal = (userIds) => {
        setSendEmailModalUserIds(userIds);
        setSendEmailModalOpen(true);
    }
    const snackbar = useSnackbar();
    let [users, setUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    let [selectedCandidates, setSelectedCandidates] = useState(new Set());
    const [loading, setLoading] = useState(true);
    const [userNotAvailable, setUserNotAvailable] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const onClickBulkEmail = () => {
        if (selectedCandidates.size === 0) return snackbar.showMessage("No Candidates Selected");
        openEmailModal([...selectedCandidates]);
    }
    const onClickDownloadExcel = async () => {
        if (selectedCandidates.size === 0) return snackbar.showMessage("No Candidates Selected");
        const data = [
            ["Name", "Email", "Mobile", "Whatsapp", "Data of Birth", "Gender", "City", "Locality", "Education", "Skills", "Experience"].map((field) => ({
                value: field,
                type: 'string'
            }))
        ];
        for (let user of selectedCandidates) {
            const resp = await getRequest('/applicants/profiles', {applicantId: user._id,});
            if (resp['status'] !== 'success' || !resp['data']) {
                snackbar.showMessage(resp["message"] || "Some Error Occurred");
                return;
            }
            const userData = [
                resp['data'].name,
                resp['data'].email,
                resp['data'].mobile,
                resp['data'].whatsapp,
                resp['data'].dob,
                resp['data'].gender,
                resp['data'].city,
                resp['data'].locality,
                resp['data'].education ? (resp['data'].education.map((item) => (item.institute + " " + item.program + " " + item.course + " " + item.passingYear))).toString() : '',
                resp['data'].skills ? resp['data'].skills.toString() : "",
                resp['data'].experience ? (resp['data'].experience.map((item) => ([item.company, item.role, item.duration].join(' '))).toString()) : '',
            ].map((item) => ({value: item, type: 'string'}))
            data.push(userData);
        }
        console.log(data);
        const config = {
            filename: "MyNiyukti_Candidates",
            sheet: {
                data: data
            }
        }
        try {
            zipcelx(config);
        } catch (err) {
            console.log(err);
            snackbar.showMessage("Error Creating Excel file");
            return;
        }

    }
    const downloadResume = async (user) => {
        try {
            const resp = await fetch(process.env.REACT_APP_ROOT_URL + "/applicants/resume?applicantId=" + user._id, {credentials: 'include'});
            if (!resp.ok) {
                const json = await resp.json();
                snackbar.showMessage(json['message']);
                return;
            }
            const blob = await resp.blob();
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = "Resume_" + user.name;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again
        } catch (err) {
            snackbar.showMessage("Unable to Download");
        }
    }

    const initializeAfterChange = () => {
        setLoading(true);
        // setJobNotAvailable(false);
        setPageNumber(1);
    }

    const handlePageChange = (event, value) => {
        setPageNumber(value);
    }

    const [openSort, setOpenSort] = React.useState(false); //Sort Dialog
    const [selectedValueSort, setSelectedValueSort] = React.useState("-date");

    const handleClickOpenSort = () => {
        setOpenSort(true);
    };

    const handleCloseSort = (value) => {
        setOpenSort(false);
        initializeAfterChange();
        setSelectedValueSort(value);
    };
    const history = useHistory()
    const urlquery = new URLSearchParams(useLocation().search)
    let jobCategories = urlquery.get("jobCategories");
    if (!jobCategories) jobCategories = "-1";
    let gender = urlquery.get("gender");
    if (!gender) gender = "-1";
    let city = urlquery.get("city");
    if (!city) city = "-1";
    let education = urlquery.get("education");
    if (!education) education = {"1": false, "2": false, "3": false, "4": false};
    else {
        let temp = education.split(",");
        education = {"1": false, "2": false, "3": false, "4": false};
        temp.forEach((element) => {
            education[element] = true
        })
    }
    let salary = [urlquery.get("minsalary") / 1000, urlquery.get("maxsalary") / 1000];
    if (!salary[0]) salary = [0, 100];
    let experience = urlquery.get("experience");
    if (!experience) experience = "-1";
    const [selectedValueFilter, setSelectedValueFilter] = React.useState(
        {gender, city, education, salary, experience, jobCategories}
    );
    const [openFilter, setOpenFilter] = React.useState(false); //Filter Dialog

    const handleFilterChange = (newValue) => {
        initializeAfterChange();
        console.log(newValue);
        urlquery.delete("jobCategories");
        jobCategories = newValue.jobCategories;
        if (newValue.jobCategories !== "-1" && newValue.jobCategories) urlquery.append("jobCategories", jobCategories)
        urlquery.delete("gender");
        gender = newValue.gender;
        if (newValue.gender !== "-1" && newValue.gender) urlquery.append("gender", gender)
        urlquery.delete("city");
        city = newValue.city;
        if (newValue.city !== "-1" && newValue.city) urlquery.append("city", city)
        urlquery.delete("education");
        education = newValue.education;
        let tempEdu = "";
        for (const key of Object.keys(education)) {
            if (education[key]) tempEdu += key + ",";
        }
        if (tempEdu && tempEdu !== "") urlquery.append("education", tempEdu)
        salary = newValue.salary;
        urlquery.delete("minsalary");
        urlquery.delete("maxsalary");
        if (salary[0]) urlquery.append("minsalary", salary[0] * 1000);
        if (salary[1]) urlquery.append("maxsalary", salary[1] * 1000);
        urlquery.delete("experience");
        experience = newValue.experience;
        if (newValue.experience && newValue.experience !== "-1") urlquery.append("experience", experience)

        setSelectedValueFilter({gender, city, education, salary, experience});
        history.push({search: urlquery.toString()})
    }

    const handleClickOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = (value) => {
        setOpenFilter(false);
    };


    useEffect(async () => {
        setUsers([]);
        setLoading(true);
        setSelectedCandidates(new Set());
        setSelectAll(false);
        let limit = 20;
        let params = {page: pageNumber, limit: limit};
        // let params = {sort: selectedValueSort, page: pageNumber, limit: 3};
        jobCategories = urlquery.get("jobCategories");
        if (jobCategories) params = {...params, 'desiredJobDetails.department[$in]': jobCategories};
        gender = urlquery.get("gender");
        if (gender) params = {...params, 'gender': gender};
        city = urlquery.get("city");
        if (city) params = {...params, 'city': city};
        education = urlquery.get("education");
        if (education) params = {...params, 'maxEducation[$in]': education};
        experience = urlquery.get("experience");
        if (experience) params = {...params, 'totalExperience[gte]': parseInt(experience)};
        salary[0] = urlquery.get("minsalary");
        salary[1] = urlquery.get("maxsalary");
        if (salary[0]) params = {
            ...params,
            'desiredJobDetails.salary[gte]': parseInt(salary[0]),
            'desiredJobDetails.salary[lte]': parseInt(salary[1])
        };
        if (jobId) params = {...params, 'recruiter': 1, "jobId": jobId};
        const resp = await getRequest(incomingURL, params);
        if (resp['status'] !== 'success') {
            snackbar.showMessage("Unable to retrieve candidates list");
        } else {
            console.log(resp['data'])
            setUsers(resp['data']['applicants']);
            setTotalPages(Math.ceil(resp['data']['totalApplicants'] / limit));                  //limit;
            setLoading(false);
            if (resp['data']['applicants'].length === 0) {
                setUserNotAvailable(true);
            } else setUserNotAvailable(false);
        }
    }, [pageNumber, selectedValueSort, history, selectedValueFilter]);
    const selectAllProfiles = (e) => {
        const checked = e.target.checked;
        setSelectAll(checked);
        if (checked) {
            selectedCandidates.add(...users);
        }
    }
    return (
        <>
            <SendEmailModal ids={sendEmailModalUserIds} open={sendEmailModalOpen} setOpen={setSendEmailModalOpen}/>
            <Container>
                <Grid container>
                    <Grid item md={3}>
                        <Hidden only={['xs','sm']}>
                            <SideFilterBox selectedValue={selectedValueFilter} setSelectedValueFilter={handleFilterChange}/>
                        </Hidden>
                    </Grid>
                    <Grid item md={9} sm={12}>
                        <Container>
                            <Grid container justify="space-between" alignItems="center">
                                <div>
                                    <Checkbox color="primary" style={{verticalAlign: "middle"}} checked={selectAll}
                                              onChange={selectAllProfiles}/>
                                    <Button variant="outlined" onClick={onClickBulkEmail}>Bulk Email</Button>
                                    <Button variant="outlined" onClick={onClickDownloadExcel}>Excel</Button>
                                </div>
                                    <Hidden mdUp>
                                        <Button variant="outlined" className={classes.filterAndSortButton}
                                                onClick={handleClickOpenFilter}>
                                            <i className="fas fa-filter"></i>Filter
                                        </Button>
                                        <FilterDialogWrapper selectedValue={selectedValueFilter} open={openFilter}
                                                             onClose={handleCloseFilter}
                                                             setSelectedValueFilter={handleFilterChange}/>
                                    </Hidden>
                                    {/*<Button*/}
                                    {/*    variant="outlined"*/}
                                    {/*    className={classes.filterAndSortButton}*/}
                                    {/*    onClick={handleClickOpenSort}*/}
                                    {/*>*/}
                                    {/*    <i className="fas fa-sort"></i>Sort*/}
                                    {/*</Button>*/}
                                    {/*<SortDialog selectedValue={selectedValueSort} open={openSort}*/}
                                    {/*            onClose={handleCloseSort}/>*/}
                            </Grid>
                            {!userNotAvailable && !loading &&
                            users.map((user, index) => {
                                return <ProfileCard key={index} user={user} sendEmailModal={openEmailModal}
                                                    onSelect={() => {
                                                        selectedCandidates.add(user);
                                                        console.log(selectedCandidates)
                                                    }} onDeSelect={() => {
                                    selectedCandidates.delete(user)
                                }} onDownloadResume={() => downloadResume(user)} selectAll={selectAll}
                                                    setSelectAll={setSelectAll}/>

                            })}

                            {!userNotAvailable && !loading && !jobId &&
                            <Grid container justify="center" alignItems="center">
                                <Grid item md={5} xs={12}>
                                    <Pagination count={totalPages} page={pageNumber} onChange={handlePageChange}
                                                variant="outlined" color="primary"/>
                                </Grid>
                            </Grid>
                            }
                            {
                                userNotAvailable && (
                                    <div style={{textAlign: "center", fontFamily: "Poppins", fontSize: "2rem"}}>No
                                        Candidates
                                        Available</div>)
                            }

                            {loading && (
                                <Loader loading={loading}/>
                            )}
                        </Container>
                    </Grid>
                </Grid>
            </Container>

        </>
    )
}
