import {logout, useAuthDispatch, useAuthState} from "../../Context";
import {Redirect} from "react-router-dom";
import {useEffect} from "react";
import {useSnackbar} from "material-ui-snackbar-provider";

export default function Logout(props) {
    const dispatch = useAuthDispatch();
    const snackbar = useSnackbar();
    const {isLoggedIn,} = useAuthState();
    useEffect(async () => {
        const resp = await logout(dispatch);
        if(resp) snackbar.showMessage("Please login");
    },[dispatch, snackbar])
    return (
        <>
            <h1 style={{textAlign:"center"}}>Logging out</h1>
            {!isLoggedIn && <Redirect to={{ pathname: '/login' }} />}
        </>
    );
}
