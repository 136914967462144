import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Container, Grid, Paper, Tab, Tabs, Typography} from "@material-ui/core";
import {FaRegUserCircle} from "react-icons/fa"
import {makeStyles} from '@material-ui/core/styles';
import PersonalBox from "./PersonalBox";
import DesiredJobDetails from "./DesiredJobDetails";
import SkillsBox from "./SkillsBox";
import EducationBox from "./EducationBox";
import {getRequest, postRequest} from "../../Utils/fetch";
import Loader from "../../Components/Loader";
import ResumeBox from "./ResumeBox";
import {useSnackbar} from 'material-ui-snackbar-provider'
import ExperienceBox from "./ExperienceBox";
import VerificationStatus from "./VerificationStatus";
import TabPanel from "../../Components/TabPanel";
import PasswordBox from "./PasswordBox";
import {useAuthState} from "../../Context";
import RecruiterProfileBox from "./RecruiterProfileBox";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        '& p': {
            color: '#4c4d4d'
        }
    },
    profilePhotoBox: {
        textAlign: "center",
        verticalAlign: "middle",
        // minHeight:theme.spacing(16),
    },
    paper: {
        width: "80%",
        padding: theme.spacing(3),
        margin: "15px auto"
    },
    loader: {
        fontSize: "200px",
    },
}))
// export const useFetch = (url, payload, fetch) => {
//     const [data, setData] = useState(null);
//     // let data = null;
//     const [error, setError] = useState('');
//     const [loading, setLoading] = useState(true);
//
//
//     return {data, error,loading};
// }
export default function EditProfile(props) {
    const classes = useStyles(props);
    let personalEditable = true;
    const authState = useAuthState();
    const profileType = authState && authState.user && authState.user.profileType;
    const [data, setData] = useState(null);
    // let data = null;
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [tabNo, setTabNo] = useState(0);
    const snackbar = useSnackbar();
    // showSnackbar({message:'hello from snackbar',type:'success',duration:6000});
    // snackbar.showMessage(
    //     'Something happened!',
    //     'Undo', () => console.log('hello')
    // )
    useEffect(() => {
        const fetchData = async () => {

            const response = await getRequest('/profile/profile', {});
            if (response['status'] === 'error') setError(response['message']);
            else setData(response['data']);
            setLoading(false);
        }
        fetchData();
    }, []);
    let personal = {
        "name": "",
        "email": "",
        "mobile": "",
        "dob": null,
        "gender": "",
        "location": "",
        "resume": ""
    };
    let skills = [];
    let desiredJobDetails = {}
    let education = []
    let resume = {}
    let experience = [];
    let isEmailVerified;
    let recruiterData;
    if (!loading && error == '' && profileType === 'applicant') {
        console.log(data);
        let temp = data.profile;
        personal = (temp == null || temp.personal == null) ? {} : {
            name: temp.personal.name,
            email: temp.personal.email,
            mobile: temp.personal.mobile,
            whatsapp: temp.personal.whatsapp,
            dob: temp.personal.dob,
            gender: temp.personal.gender,
            city: temp.personal.city,
            locality: temp.personal.locality,
        }
        skills = (temp == null || temp.skills == null) ? [] : temp.skills;
        desiredJobDetails = (temp == null || temp.desiredJobDetails == null) ? {} : temp.desiredJobDetails;
        education = (temp == null || temp.education == null) ? [] : temp.education;
        experience = (temp == null || temp.experience == null) ? [] : temp.experience;
        resume = (temp == null || temp.resume == null) ? {} : {
            file: null,
            resume: temp.resume
        }
        isEmailVerified = temp.isEmailVerified;
    }
    else if(!loading && error == '' && profileType === 'recruiter') {
        recruiterData = {
            ...data.profile
        }
        isEmailVerified = recruiterData.isVerified;
        if(data.profile && typeof data.profile.contact !== 'string') {
            recruiterData.contact = '';
        }
    }
    const setFinalData = async (key, val) => {
        console.log({
            ...(data.profile),
            [key]: val,
        })
        let dataToSend = key === 'personal' ? val : {[key]: val}
        const response = await postRequest('/profile/profile', dataToSend);
        if (response['status'] != 'success') {
            snackbar.showMessage(response.message);
            return false;
        }
        setData({
            ...data,
            profile: response.data.profile,
        })
        snackbar.showMessage("Profile Updated");
        return true;
    }
    return (
        <>
            <Container maxWidth="md" className={classes.container}>
                <Loader loading={loading}/>
                {/*<Typography variant="h5" style={{fontColor: "red"}}>{error}</Typography>*/}
                {!loading && <>
                    <Tabs value={tabNo} onChange={(event, newValue) => setTabNo(newValue)} indicatorColor={"primary"}
                          textColor={"primary"} centered>
                        <Tab label="Profile"/>
                        <Tab label="Security"/>
                    </Tabs>
                    <TabPanel value={tabNo} index={0}>
                        <VerificationStatus data={isEmailVerified}/>
                        {profileType === 'applicant'?
                        <>
                            <PersonalBox data={personal} setFinalData={setFinalData}/>
                            <ResumeBox data={resume} setFinalData={setFinalData}/>
                            <EducationBox data={education} setFinalData={setFinalData}/>
                            <ExperienceBox data={experience} setFinalData={setFinalData}/>
                            <DesiredJobDetails data={desiredJobDetails} setFinalData={setFinalData}/>
                            <SkillsBox data={skills} setFinalData={setFinalData}/>
                        </>
                            :
                         <>
                            <RecruiterProfileBox data={recruiterData} setFinalData={setFinalData}/>
                         </>
                        }
                    </TabPanel>
                    <TabPanel value={tabNo} index={1}>
                        <PasswordBox data={{}}/>
                    </TabPanel>
                </>}
            </Container>

        </>
    )
}
