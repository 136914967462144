import {postRequest} from "../../Utils/fetch";
import {Button, Dialog, DialogTitle, Grid, makeStyles} from "@material-ui/core";
import {useEffect, useRef, useState} from "react";
import {useSnackbar} from "material-ui-snackbar-provider";
import {loginGoogle, manuelLoginUser} from "../../Context/actions";
import {useAuthDispatch, useAuthState} from "../../Context";
import history from "../../Utils/history";

const useStyles = makeStyles(theme => ({
    alignCenter: {
        textAlign: "center"
    },
    gridContainer: {
        padding: "30px"
    },
    gridItem: {
        padding: "20px",
        textAlign: "center"
    }
}))

function SelectAccountType({onClose, open, ...props}) {
    const classes = useStyles();
    const handleClose = () => {
        onClose()
    }
    return (
        <Dialog open={open} aria-labelledby="select-account-type-dialog" fullWidth>
            <DialogTitle className={classes.alignCenter}>Select Account Type</DialogTitle>
            <Grid container>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                    <Button variant={"contained"} color={"primary"} onClick={() => onClose("applicant")}>I'm a Job
                        Seeker</Button>
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                    <Button variant={"contained"} color={"primary"} onClick={() => onClose("recruiter")}>I'm a
                        Recruiter</Button>
                </Grid>

            </Grid>
        </Dialog>
    )
}

export default function GoogleOneTap(props) {
    const [dialog, setDialog] = useState({open: false, context: "none"});
    const {isLoggedIn} = useAuthState();
    const [isAvailable, setIsAvailable] = useState(false);
    const googleData = useRef();
    // const [googleData, setGoogleData] = useState(null);
    const snackbar = useSnackbar();
    const dispatch = useAuthDispatch();
    const onDialogClose = async (accountType) => {
        console.log(accountType);
        if (dialog.context === 'login') {
            setDialog({open: false, context: "none"});
            await loginUser(accountType);
        } else if (dialog.context === 'signup') {
            setDialog({open: false, context: "none"});
            if (accountType === 'applicant') {
                history.push({
                    pathname: '/signup',
                    state: {
                        signupType: "google",
                        token: googleData.current.credential,
                    }
                });
            } else if (accountType === 'recruiter') {
                history.push({
                    pathname: '/recruiter/signup',
                    state: {
                        signupType: "google",
                        token: googleData.current.credential,
                    }
                });
            }
        }
    }
    const loginUser = async (profileType) => {
        if (!googleData.current) {
            // console.log(googleData)
            return snackbar.showMessage("Some Error Occurred");
        }
        const {message, error} = await loginGoogle(dispatch, {token: googleData.current.credential, profileType});
        if (error) {
            if (error === 'selectAccountType') {
                setDialog({open: true, context: "login"});
                snackbar.showMessage(message || "Select Account Type");
            } else if (error === 'userNotFound') {
                setDialog({open: true, context: "signup"});
                snackbar.showMessage("Select Account Type");
            }
        }
    }
    const handleCredentialResponse = async (data, profileType) => {
        // console.log(data);
        googleData.current = data;
        // setGoogleData(data);
        await loginUser();
    }
    window.onload = function () {
        // eslint-disable-next-line no-undef
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse,
            cancel_on_tap_outside: true
        });
        setIsAvailable(true);
    }
    useEffect(() => {
        if (isLoggedIn || !isAvailable) return;
        // console.log('show gsi');
        // eslint-disable-next-line no-undef
        google.accounts.id.prompt();
        // eslint-disable-next-line no-undef
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse,
            cancel_on_tap_outside: true
        });
    }, [isLoggedIn, isAvailable])

    return (
        <SelectAccountType onClose={onDialogClose} open={dialog.open}/>
    );
}
