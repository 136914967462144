import React, {useState} from "react";
import Box from "./Box";
import {Button, Grid, Typography} from "@material-ui/core";
import {postRequest} from "../../Utils/fetch";

export default function ResumeBox(props) {
    const {data: finalDetails, setFinalData: setFinalDetails} = props;

    const [editMode, setEditMode] = useState(false);
    const [details, setDetails] = useState(finalDetails);
    const [error, setError] = useState(null);
    const onClickEdit = () => {
        setEditMode(!editMode);
        setDetails(finalDetails);
    };
    const resumeSelectedHandler = (e) => {
        console.log(e.target.files);
        setDetails({
            ...details,
            'resume': e.target.files[0].name,
            'file': e.target.files[0]
        })
    }
    const onClickUpload = async (e) => {
        const formData = new FormData();
        if(details.file == null) { setError("Please Select a File"); return; }
        formData.append('resume', details.file);
        const response = await postRequest('/profile/resume',formData);
        console.log(response)
        if(response['status'] != 'success') return setError(response['message']);
        finalDetails.resume = details.resume;
        setError(null);
        setEditMode(false);
    }
    return (
        <>
            <Box heading="Resume" editMode={editMode} setEditMode={setEditMode} onClickEdit={onClickEdit}>
                <Grid container direction="column">
                    <Grid item>
                        { error && <Typography style={{color: "red"}}>{error}</Typography>}
                        {editMode ?
                            <>
                                <input type="file" accept="application/pdf, application/vnd.ms-excel"
                                       onChange={resumeSelectedHandler}/>
                                <Button variant="contained" color="primary" onClick={onClickUpload} >Upload</Button>
                            </>
                            :
                            <Typography>{finalDetails.resume}</Typography>
                        }

                    </Grid>

                </Grid>
            </Box>

        </>
    )
}
