import PostJob from "../PostJob/PostJob";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getRequest} from "../../Utils/fetch";
import {useSnackbar} from "material-ui-snackbar-provider";
import Loader from "../../Components/Loader";

export default function EditJob(props) {
    // const data = {
    //     title: "SDE",
    //     category: "Computer Operator",
    //     experience: 4,
    //     jobType: 2,
    //     education: 3,
    //     city: ['Sitapur','Delhi'],
    //     minsalary: 30000,
    //     maxsalary: 40000,
    //     skills: ['python','react'],
    //     description: "SDE",
    //     company: "Amazon",
    //     companyContactName: "Steve Jobs",
    //     companyContactPhone: "9005902201",
    //     companyEmail: "prashushakya@gmail.com",
    //     companyJobAddress:"Shakya Bhawan Sri Nagar Colony Sitapur"
    // }
    const jobId = useParams()['jobId'];
    const snackbar = useSnackbar();
    const [loading,setLoading] = useState(true);
    let [data, setData] = useState({});
    useEffect(async () => {
        const resp = await getRequest('/jobs/jobs',{jobId: jobId});
        if(!resp['status'] || resp['status'] !== 'success') {
            snackbar.showMessage(resp['message'] || 'Some Error Occurred');
        } else {
            setData(resp['data']);
            setLoading(false);
        }
    },[jobId])
    return (
        <>
            <Loader loading={loading}/>
            { loading === false ? <PostJob data={data} mode='update'/> : ''}
        </>
    );
}
