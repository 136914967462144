import {CircularProgress} from "@material-ui/core";
import React from "react";

export default function Loader({loading}) {
    return (
        <>
            {loading && <CircularProgress size={"80px"} style={{display: "block", margin: "50px auto"}}/>}
        </>
    );
}
