import {Button, makeStyles, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import Box from "./Box";
import FormField from "./FormField";
import {postRequest} from "../../Utils/fetch";
import {useSnackbar} from "material-ui-snackbar-provider";

const useStyles = makeStyles((theme) => ({
    editFormButtons: {
        margin: theme.spacing(1)
    },
    error: {
        color: "red",
    }
}))
export default function PasswordBox(props) {
    const {data} = props;
    const [editMode, setEditMode] = useState(false);
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [details, setDetails] = useState({
        currentPassword:'',
        newPassword:'',
        confirmNewPassword:''
    });
    const handleDetailsChange = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value,
        })
    };
    const {register, handleSubmit, errors, control} = useForm({});
    const onClickEdit = () => {
        setEditMode(!editMode);
    };
    const fields = {
        "Confirm Current Password": [
            <>
            <input type="password" placeholder="Enter your password" name="currentPassword" onChange={handleDetailsChange}
                   ref={register(
                       {
                           required: 'You must provide your current password.',
                           minLength: {
                               value: 8,
                               message: 'Your password must be greater than 8 characters',
                           },
                       })}
                   // error={!!errors.currentPassword}
            />
                {errors.currentPassword && (<span className={classes.error}>{errors.currentPassword.message}</span>)}
            </>,
            <Typography></Typography>
        ],
        "New Password": [
            <>
                <input type="password" placeholder="Enter your New password" name="newPassword" onChange={handleDetailsChange}
                       ref={register(
                           {
                               required: 'You must provide your New password.',
                               minLength: {
                                   value: 8,
                                   message: 'Your password must be greater than 8 characters',
                               },
                           })}
                       // error={!!errors.newPassword}
                />
                {errors.newPassword && (<span className={classes.error}>{errors.newPassword.message}</span>)}
            </>,
            <Typography></Typography>
        ],
        "Confirm New Password": [
            <>
                <input type="password" placeholder="Confirm your new password" name="confirmNewPassword" onChange={handleDetailsChange}
                       ref={register(
                           {
                               required: 'You must provide your confirm password.',
                               minLength: {
                                   value: 8,
                                   message: 'Your password must be greater than 8 characters',
                               },
                               validate: value => value === details.newPassword || "The passwords do not match"
                           })}
                       // error={!!errors.confirmNewPassword}
                />
                {errors.confirmNewPassword && (<span className={classes.error}>{errors.confirmNewPassword.message}</span>)}
            </>,
            <Typography></Typography>
        ]
    }
    const onSubmit = async (data) => {
        console.log(data);
        try {
            const resp = await postRequest('/auth/changePassword', data);
            if(resp['status'] !== 'success') {
                return snackbar.showMessage(resp['message'] || 'Some Error Occured');
            }
            setEditMode(false);
            console.log(resp['data']);
        }
        catch (err) {
            console.log(err);
            snackbar.showMessage('Some Error Occurred');
        }
    }
    const editFormButtons =
        <div>
            <Button variant="contained" color="primary" className={classes.editFormButtons}
                    type={"submit"}>Save</Button>
            <Button variant="contained" color="default" className={classes.editFormButtons}
                    onClick={onClickEdit}>Cancel</Button>
        </div>
    return (
        <Box heading={"Password"} setEditMode={setEditMode} editMode={editMode} onClickEdit={onClickEdit}>
            {editMode ?
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    {Object.keys(fields).map((field, index) => (
                        <FormField key={index} field={field} formElement={fields[field][0]} value={fields[field][1]}
                                   editMode={true}/>
                    ))}
                    <FormField field="" formElement={editFormButtons} value={""} editMode={true}/>
                </form>

                :
                <Typography>
                    Create or update your password.
                </Typography>
            }
        </Box>
    )
}
