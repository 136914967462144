import React from 'react'
import { Box, Typography } from "@material-ui/core"

function TabPanelFilter(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-filter-${index}`}
        aria-labelledby={`vertical-tab-filter-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

export default TabPanelFilter;
