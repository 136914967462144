import {Container, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Loader from "../../Components/Loader";
import {getRequest} from "../../Utils/fetch";
import {useParams} from "react-router";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
}));
export default function EmailVerification(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const {id} = useParams();
    useEffect(async () => {
        const resp = await getRequest('/auth/emailVerification/'+ (id || ''),null)
        if(resp.status === 'success') {
            setLoading(false);
        } else {
            setLoading(false);
            setError(resp['message'] || 'Account Verification Failed');
        }
    },[])
    return (
        <Container maxWidth="md" style={{height: "80vh"}} className={classes.container}>
            {/*<Loader loading={loading}/>*/}
            {!loading && error=='' && <Typography variant={"h4"} style={{textAlign: "center"}}>Your Account is now Verified</Typography>}
            {!loading && error!='' && <Typography variant={"h4"} style={{textAlign: "center",color: "red"}}>{error}</Typography>}
        </Container>

    )
}
