import React, {useState} from "react";
import {Button, Grid, IconButton, makeStyles, TextField, Typography} from "@material-ui/core";
import Box from "./Box";
import {FaTimes} from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
    pickers: {},
    editFormButtons: {
        margin: theme.spacing(1)
    },
    input: {
        margin: '5px 20px',
        width: '90%'
    }
}))

export default function ExperienceBox(props) {
    const {data: finalDetails, setFinalData: setFinalDetails} = props;
    const [editMode, setEditMode] = useState(false);
    const [details, setDetails] = useState(finalDetails);
    const [newExperience, setNewExperience] = useState({
        company: '',
        duration: '',
        role: 0
    });
    const classes = useStyles();
    const onClickEdit = () => {
        setEditMode(!editMode);
        setDetails(finalDetails);
    };
    const onChangeNewEducation = (e) => {
        setNewExperience({
            ...newExperience,
            [e.target.name]: e.target.value,
        })
    }
    const onClickAdd = (e) => {
        setDetails([
            ...details,
            newExperience
        ])
    }
    const onClickSave = (e) => {
        setFinalDetails('experience', details);
        setEditMode(false);
    }
    const deleteExperience = (index) => {
        console.log(index);
        setDetails(details.filter((_,i) => i!==index));
    }
    const editFormButtons =
        <div>
            <Button variant="contained" color="primary" className={classes.editFormButtons} onClick={onClickSave}>Save</Button>
            <Button variant="contained" color="default" className={classes.editFormButtons} onClick={onClickEdit}>Cancel</Button>
        </div>
    return (
        <>
            <Box heading="Experience*" editMode={editMode} setEditMode={setEditMode} onClickEdit={onClickEdit}>
                <Grid container direction="column">
                    {details.map((education, index) => (

                            <Grid xs={12} item container key={index}>
                                <Grid key={index} xs={6} item>
                                    <Typography style={{fontWeight: "bold"}}>{education['company']}</Typography>
                                    <Typography>Experience- {education['duration']} Role-{education['role']}  </Typography>
                                </Grid>
                                <Grid xs={2} item>
                                    {editMode ?
                                        <IconButton color="secondary" aria-label="Remove Skill" onClick={() => deleteExperience(index)}>
                                            <FaTimes className={classes.deleteSkillButton}/>
                                        </IconButton>
                                        :
                                        ""
                                    }
                                </Grid>
                            </Grid>
                    ))}

                    {editMode &&

                    <form noValidate autoComplete="off" className={classes.form}>
                        <Grid item container>
                            <Grid item md={6} sm={12}>
                                <TextField className={classes.input} value={newExperience.company} name="company"
                                           onChange={onChangeNewEducation}
                                           label="Company"/>
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <TextField type="number" className={classes.input} value={newExperience.duration} name="duration"
                                           onChange={onChangeNewEducation}
                                           label="Duration"/>
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <TextField className={classes.input} value={newExperience.course} name="role"
                                           onChange={onChangeNewEducation} label="Role"/>
                            </Grid>
                            <Button variant="outlined" color="primary" className={classes.editFormButtons} onClick={onClickAdd}>Add</Button>

                        </Grid>
                    </form>
                    }
                    {editMode && editFormButtons}
                </Grid>
            </Box>
        </>
    );
}
