import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useForm} from "react-hook-form";
import {loginUser, useAuthDispatch, useAuthState} from "../../Context";
import {loginGoogle, manuelLoginUser, signUpApplicant, signUpUser} from "../../Context/actions";
import SignInStyle from "../../Styling/SignInStyle";
import GoogleSignInBtn from "../../Components/GoogleSignInBtn";
import GoogleLogin from "react-google-login";
import history from "../../Utils/history";
import {useSnackbar} from "material-ui-snackbar-provider";
import {Autocomplete} from "@material-ui/lab";
import CitiesList from "../../assets/CitiesList.json";
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://www.myniyukti.com">
                My Niyukti
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error: {
        color: "red",
    },
    field: {
        margin: "40px"
    }
}));

export default function SignUpForm(props) {
    const classes = makeStyles(SignInStyle)();
    const signUpType = props.type || 'password';
    const token = props.token;
    const dispatch = useAuthDispatch();
    const {loading, errorMessage} = useAuthState();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        city:'',
        password: '',
        passwordConfirm: ''
    });
    const snackbar = useSnackbar();
    const {register, handleSubmit, errors, setValue, control, reset} = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            name: '',
            email: '',
            mobile: '',
            password: '',
            passwordConfirm: ''
        },
    });
    useEffect(async () => {
        if(signUpType !== 'google') return;
        try{
            const resp = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${token}`);
            const data = await resp.json();
            reset({email: data.email, name: data.name});
            setFormData({...formData, email: data.email, name: data.name});
        }
        catch (err) {
            console.log(err)
            snackbar.showMessage("Some Error Occurred");
        }
    }, [props.token, props.type, reset])

    useEffect(() => {
        register('city',{
            validate: (value) => {
                if(!value) return 'This is required.';
                return value.TextName.length || 'This is required.'
            },
        })
    },[register])

    const onSubmit = async (data) => {
        console.log(data, formData);
        // console.log(formData)
        let loginPayload = {};
        if(signUpType === 'google') {
            loginPayload = {
                mobile: formData.mobile,
                city: formData.city,
                token: token
            }
        } else {
            loginPayload = {name: formData.name, email: formData.email, mobile: formData.mobile, city: formData.city, password: formData.password, passwordConfirm: formData.passwordConfirm}
        }
        try {
            let resp = await signUpApplicant(dispatch,loginPayload );
            if (!resp.user) return;
            await manuelLoginUser(dispatch, {user: resp.user});
            if (resp.user.profileType === 'applicant') history.push('/findjobs');
            else if (resp.user.profileType === 'recruiter') history.push('/recruiter/');
            snackbar.showMessage("Account Successfully Created.");
        } catch (error) {
            console.log(error);
        }
    };
    const handleCityChange = (e,options) => {
        setFormData({
            ...formData,
            city: (options && options.TextName)? options.TextName: null
        })
        setValue('city',options)
    }
    const handleGoogleSignUp = async (googleData) => {
        history.push({
            pathname: '/signup',
            state: {
                signupType: "google",
                token: googleData.tokenId,
            }
        });
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container spacing={3}>
                        {errorMessage ? <p className={classes.error}>{errorMessage}</p> : null}
                        <Grid item xs={12}>
                            <TextField
                                value={formData.name}
                                onChange={(e) => setFormData({...formData, name: e.target.value})}
                                disabled={loading || signUpType === 'google'}
                                inputRef={register({
                                    required: 'You must provide your Name!',
                                    pattern: {
                                        value: /[a-zA-Z][a-zA-Z ]{2,}/,
                                        message: 'You must provide a valid Name!',
                                    },
                                })}
                                error={!!errors.name}
                                className={classes.field}
                                autoComplete="name"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                autoFocus
                            />
                            {errors.name && (
                                <span className={classes.error}>{errors.name.message}</span>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={formData.email}
                                onChange={(e) => setFormData({...formData, email: e.target.value})}
                                disabled={loading || signUpType === 'google'}
                                inputRef={register({
                                    required: 'You must provide the email address!',
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'You must provide a valid email address!',
                                    },
                                })}
                                error={!!errors.email}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                            />
                            {errors.email && (
                                <span className={classes.error}>{errors.email.message}</span>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={formData.mobile}
                                onChange={(e) => setFormData({...formData, mobile: e.target.value})}
                                disabled={loading}
                                inputRef={register({
                                    required: 'You must provide a Mobile Number!',
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: 'You must provide a valid 10 digit mobile number',
                                    },
                                })}
                                error={!!errors.mobile}
                                className={classes.field}
                                variant="outlined"
                                required
                                fullWidth
                                id="mobile"
                                label="Mobile Number"
                                name="mobile"
                                autoComplete="mobile"
                            />
                            {errors.mobile && (
                                <span className={classes.error}>{errors.mobile.message}</span>
                            )}
                        </Grid>
                        <Grid item xs={12}>

                            <Autocomplete
                                options={CitiesList}
                                disabled={loading}
                                getOptionLabel={(option) => option.TextName}
                                id="city"
                                fullWidth
                                onChange={handleCityChange}
                                autoComplete="off"
                                renderInput={(params) => <TextField {...params} error={!!errors.city} autoComplete="off" label="City" variant="outlined" required className={classes.field}/>}
                            />
                            {errors.city && (
                                <span className={classes.error}>{errors.city.message}</span>
                            )}
                        </Grid>
                        {signUpType !== 'google' &&
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        value={formData.password}
                                        onChange={(e) => setFormData({...formData, password: e.target.value})}
                                        inputRef={register({
                                            required: 'You must provide a password.',
                                            minLength: {
                                                value: 8,
                                                message: 'Your password must be greater than 8 characters',
                                            },
                                        })}
                                        error={!!errors.password}
                                        className={classes.field}
                                        disabled={loading}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                    {errors.password && (
                                        <span className={classes.error}>{errors.password.message}</span>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={formData.passwordConfirm}
                                        onChange={(e) => setFormData({...formData, passwordConfirm: e.target.value})}
                                        inputRef={register({
                                            required: 'You must provide a password.',
                                            minLength: {
                                                value: 8,
                                                message: 'Your password must be greater than 8 characters',
                                            },
                                            validate: value => value === formData.password || "The passwords do not match"
                                        })}
                                        error={!!errors.passwordConfirm}
                                        disabled={loading}
                                        className={classes.field}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="passwordConfirm"
                                        label="Confirm Password"
                                        type="password"
                                        id="password-confirm"
                                        autoComplete="current-password"
                                    />
                                    {errors.passwordConfirm && (
                                        <span className={classes.error}>{errors.passwordConfirm.message}</span>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <GoogleLogin
                                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                        render={(props) => (<GoogleSignInBtn btnText="Sign up with Google" {...props}/>)}
                                        onSuccess={handleGoogleSignUp}
                                        // onFailure={handleGoogleSignUp}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                </Grid>
                            </>
                        }

                        {/*<Grid item xs={12}>*/}
                        {/*  <FormControlLabel*/}
                        {/*    control={<Checkbox value="allowExtraEmails" color="primary" />}*/}
                        {/*    label="I want to receive inspiration, marketing promotions and updates via email."*/}
                        {/*  />*/}
                        {/*</Grid>*/}
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading}
                    >
                        Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/signin" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            {/*<Box mt={5}>*/}
            {/*  <Copyright />*/}
            {/*</Box>*/}
        </Container>
    );
}
