import React, {useState} from 'react'
import {Button, Grid, IconButton, makeStyles, Typography} from "@material-ui/core";
import Box from "./Box";
import {FaTimes} from "react-icons/fa"

const useStyles = makeStyles((theme) => ({
    pickers: {},
    deleteSkillButton: {
        height: "20px"
    },
    editFormButtons: {
        margin: theme.spacing(1)
    }
}))
export default function SkillsBox(props) {
    const {data: finalDetails, setFinalData: setFinalDetails} = props;
    const [editMode, setEditMode] = useState(false);
    const classes = useStyles();
    // const finalDetails = [
    //     ["Hindi"],
    //     ["English"],
    //     ["Maths"]
    // ];
    const [details, setDetails] = useState(finalDetails);
    let [newSkill, setNewSkill] = useState("");
    const onClickEdit = () => {
        setEditMode(!editMode);
        setDetails(finalDetails);
    };
    const onClickSave = (e) => {
        setFinalDetails('skills', details);
        setEditMode(false);
    }
    const editFormButtons =
        <div>
            <Button variant="contained" color="primary" className={classes.editFormButtons} onClick={onClickSave}>Save</Button>
            <Button variant="contained" color="default" className={classes.editFormButtons} onClick={onClickEdit}>Cancel</Button>
        </div>

    const onClickSkillBtn = (e) => {
        if(newSkill == null || newSkill === "") return;
        setDetails([
            ...details,
            newSkill
        ])
        setNewSkill("");
    }
    const onChangeAddSkill = e => {
        setNewSkill(e.target.value);
    }
    const deleteSkill = (index) => {
        console.log(index);
        setDetails(details.filter((_,i) => i!==index));
    }
    return (
        <>
            <Box heading="Skills*" editMode={editMode} setEditMode={setEditMode} onClickEdit={onClickEdit}>
                <Grid container direction="column">
                    {details.map((skill,index) => (
                        <Grid item key={index}>
                            <Typography style={{display: "inline-block"}}>{skill}</Typography>
                            {editMode ?
                                <IconButton color="secondary" aria-label="Remove Skill" onClick={() => deleteSkill(index)}>
                                    <FaTimes className={classes.deleteSkillButton}/>
                                </IconButton>
                                :
                                ""
                            }
                        </Grid>
                    ))}
                    {editMode ?
                        <Grid item>
                            <input type="text" placeholder="Skill Name" onChange={onChangeAddSkill} value={newSkill}/>
                            <Button variant="outlined" color="primary" className={classes.editFormButtons} onClick={onClickSkillBtn}>Add Skill</Button>
                        </Grid>
                        :
                        ""
                    }
                    {editMode ?
                        <Grid item>
                            {editFormButtons}
                        </Grid>
                        :
                        ""}
                </Grid>

            </Box>
        </>
    )
}
