import history from "./history";

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

// Another
const _apiHost = process.env.REACT_APP_ROOT_URL;

async function request(url, params, method = 'GET') {
    
    // url = `${ROOT_URL}/`+`${url}`;
    let headers = {};
    if(!(params instanceof FormData)) {
        headers = {
            'Content-Type': (params instanceof FormData)? 'multipart/form-data':'application/json'
        }
    }
    const options = {
        method,
        headers: headers,
        credentials: 'include'
    };

    if (params) {
        if (method === 'GET') {
            url += '?' + objectToQueryString(params);
        } else {
            options.body = (params instanceof FormData)? params: JSON.stringify(params);
        }
    }

    const response = await fetch(_apiHost + url, options);
    if(response.status === 401) {
        // const dispatch = useAuthDispatch();
        // await logout(dispatch);

        history.push('/logout');
        return generateErrorResponse("Please Login!!");
    }
    const result = await response.json();
    if(response.status === 400) {
        try {
            return generateErrorResponse(result.message);
        } catch (err) {
            return generateErrorResponse('Some Error Occurred');
        }
    }
    if (!response.ok) {
        return generateErrorResponse('The server responded with an unexpected status.');
    }

    return result;

}

function objectToQueryString(obj) {
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

function generateErrorResponse(message) {
    return {
        status : 'error',
        message
    };
}

export function getRequest(url, params) {
    return request(url, params);
}

export function postRequest(url, params) {
    return request(url, params, 'POST');
}

export function putRequest(url, params) {
    return request(url, params, 'PUT');
}

export function deleteRequest(url, params) {
    return request(url, params, 'DELETE');
}

