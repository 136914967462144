import React from "react";
import {Radio, RadioGroup, FormControlLabel, FormControl} from '@material-ui/core/';
import {makeStyles, useTheme} from "@material-ui/core/styles";

// const people = [
//   "Siri",
//   "Alexa",
//   "Google",
//   "Facebook",
//   "Twitter",
//   "Linkedin",
//   "Sinkedin"
// ];

const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        overflow: "auto"
    },
    search: {
        padding: "-1px"
    }
}));

export default function FilterOptions(props) {
    const classes = useStyles();
    const people = props.option;
    const [searchTerm, setSearchTerm] = React.useState("");
    const [value, setValue] = React.useState(props.value);
    const handleSearchChange = event => {
        setSearchTerm(event.target.value);
    };

    const results = !searchTerm
        ? people
        : people.filter(person =>
            person.TextName.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        );

    const handleChange = (event) => {
        setValue(event.target.value);
        props.handleChange(event.target.value);
    };

    return (
        <>
            <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.search}
            />
            <FormControl component="fieldset" className={classes.root}>
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <FormControlLabel value={"-1"} control={<Radio/>} label="None"/>
                    {results.map((item, index) => {
                            if (item.ValueId) return (
                                <FormControlLabel value={item.ValueId} control={<Radio/>} label={item.TextName}
                                                  key={index}/>)
                            else return (<FormControlLabel value={item} control={<Radio/>} label={item} key={index}/>)
                        }
                    )}
                </RadioGroup>
            </FormControl>
        </>
    );
}
