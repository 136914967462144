import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-material-ui-carousel";
import history from "../../../Utils/history";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Container,
  Paper,
  Button,
} from "@material-ui/core";
import interview from "../../../assets/trainingServices/interview.jpeg";
import resume from "../../../assets/trainingServices/resume.jpeg";
import personality from "../../../assets/trainingServices/personality.jpeg";
import jobAssistant from "../../../assets/trainingServices/jobAssistant.jpeg";
import {useAuthState} from "../../../Context";

const useStyles = makeStyles((theme) => ({
  description: {
    fontFamily: "Montserrat",
    color: "white",
   fontSize:"1.3rem"
  }
}));

function TrainingServices() {
  const classes = useStyles();
  const {isLoggedIn} = useAuthState();
  const onClickPay = () => {
      if(!isLoggedIn) return history.push('/login');
      let form = document.createElement("form");
      let packageName = document.createElement("input");

      form.method = "POST";
      form.action = "http://localhost:5000/v1/payment/initiate";
      packageName.value = "Early_Bird";
      packageName.name = "packageName";
      form.appendChild(packageName);
      document.body.appendChild(form);
      form.submit();
  }

  let items = [
    {
      name: "Interview Skills & Technique",
      cost: "299",
      about: "Interview Advice That Will Help You Get a Job Offer",
      description: <ul>
        <li className={classes.description}>Learn the Interview Funnel model based on four questions to get an in-depth assessment</li>
        <li className={classes.description}>Learn the Interview Funnel model based on four questions to get an in-depth assessment</li>
        <li className={classes.description}>Draw on a library of forty-eight competencies or incorporate your own to identify consistent benchmarks that determine job success</li>
        </ul>
      ,
      image: interview,
    },
    {
      name: "Resume Building",
      cost: "offer",
      about: "Take your application to the next level",
      description: <ul>
        <li className={classes.description}>Making a resume is the first step of any job search. Not sure how to make a resume??</li>
        <li className={classes.description}>Make a perfect resume in 2021 and get your dream job using by using our resume building services.</li>
        <li className={classes.description}>We’ve developed our resume builder with one goal in mind: to help you find a great job faster. Our resume maker comes with ATS-optimized layouts and a wizard that guides you through every step of the process.</li>
        <li className={classes.description}>Plus, you’ll get ready-to-use AI-generated suggestions for every section of your resume, personalized to match your desired position. Don’t feel like writing? Just pick what sounds like you, then drag and drop.</li>
        </ul>
      ,
      image: resume,
    },
    {
      name: "Personality Development",
      cost: "399",
      about: "",
      description: <ul>
        <li className={classes.description}>Our ulterior motive for introducing this personality development course is to assist people in realizing their true potential and teach some important interpersonal skills. We provide training to build or improve inner traits like positive attitude, confidence, body language, etc to guide students, working professionals and entrepreneurs towards greater success. As personality development is about the overall development of an individual.</li>
        <li className={classes.description}>Building a personality is easy but building a dynamic one requires training from professionals and Classes of Professional Studies provides full-fledged personality development course in Delhi. Since ignoring knowledge is not really the kind of goal pursued by Classes of Professional Studies, we offer certification for professional etiquette training as well. We want our students to be ready as ripe fruit, picked up by top multinationals.</li>
        </ul>
      ,
      image: personality,
    },
    {
      name: "Job Assistant",
      cost: "199",
      about: "",
      description: <ul>
        <li className={classes.description}>Finding a dream job is like finding a pearl in an oyster. With the increasing competition, it has become very difficult for jobseekers to secure a job in good company.</li>
        <li className={classes.description}>We strive hard to elaborate jobseekers dreams by providing them the end to end solution related to recruitment problem. Vast database of potential jobs from different industry has assisted us to choose the best from the pool of jobs for jobseekers.</li>
        <li className={classes.description}>We commit to identify the right talent after thoroughly understanding individual skills and goals thus helping them secure the right jobs.</li>
        <li className={classes.description}>Will provide 2 interview opportunity.</li>
        </ul>
      ,
      image: jobAssistant,
    },
  ];

  return (
    <Container maxWidth="md" style={{ marginTop: "6vh" }}>
      <Carousel>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>

      <Typography variant="h3" gutterBottom style={{textAlign:"center", fontFamily:"Poppins"}}>
      Early Bird Offer- Get All 4 in Just 199/- (Register Today) &#129321;
      </Typography>
        {/*<Button onClick={onClickPay} variant={"contained"} color="primary" style={{display: "block", margin: "auto"}} size={"large"}>Get This Package</Button>*/}
    </Container>
  );
}

function Item(props) {
  return (
    <Paper
      style={{
        position: "relative",
        overflow: "hidden",
        padding: "16px 60px 10px 75px",
        border: "1px dashed green",
        minHeight: "65vh",
        background: "#1d2c3e",
      }}
    >
      <div style={{ position: "relative", zIndex: "0", }}>
        <h2 style={{ fontFamily: "Poppins", color:"white", fontSize:"3rem" }}>
          {props.item.name}
        </h2>
        <Typography variant="subtitle1" gutterBottom style={{fontFamily: "Montserrat", color:"white", fontSize:"1.8rem"}}>{props.item.about}</Typography>
        <Typography variant="caption" display="block" gutterBottom style={{color:"white", fontSize:"1.5rem"}}>
          Cost- {(props.item.cost=== 'offer')?<i className="fas fa-rupee-sign"><span style={{textDecorationLine: "line-through"}}>99</span> free in offer</i>:<i className="fas fa-rupee-sign">{props.item.cost}</i>}
        </Typography>
        {props.item.description}
      </div>

      <img
        src={props.item.image}
        alt=""
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          opacity: "0.3",
          zIndex:"0",
        }}
      />
    </Paper>
  );
}

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`vertical-tabpanel-${index}`}
//         aria-labelledby={`vertical-tab-${index}`}
//         {...other}
//       >
//         {value === index && (
//           <Box p={3}>
//             {children}
//           </Box>
//         )}
//       </div>
//     );
//   }

// function a11yProps(index) {
//     return {
//       id: `vertical-tab-${index}`,
//       'aria-controls': `vertical-tabpanel-${index}`,
//     };
//   }

// const useStyles = makeStyles((theme) => ({
//     root: {
//       flexGrow: 1,
//       backgroundColor: theme.palette.background.paper,
//       display: 'flex',
//       marginTop: '2rem',
//       marginBottom: '4rem',
//       height: '500px'
//     },
//     tabs: {
//       borderRight: `1px solid ${theme.palette.divider}`,
//     },
//   }));

// function TrainingServices() {
//   const classes = useStyles();
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <Container className={classes.root} maxWidth="md">
//       <Tabs
//         orientation="vertical"
//         variant="scrollable"
//         value={value}
//         onChange={handleChange}
//         aria-label="Vertical tabs example"
//         className={classes.tabs}
//       >
//         <Tab label="Interview Skills & Technique" {...a11yProps(0)} />
//         <Tab label="Resume Building" {...a11yProps(1)} />
//         <Tab label="Personality Development" {...a11yProps(2)} />
//         <Tab label="Job Assistant" {...a11yProps(3)} />
//       </Tabs>
//       <TabPanel value={value} index={0}>
//       <Typography variant="caption" display="block" gutterBottom>Cost- <i className="fas fa-rupee-sign">500</i></Typography>
//         <ul>
//             <li>Interview Advice That Will Help You Get a Job Offer</li>
//             <li>Learn the Interview Funnel model based on four questions to get an in-depth assessment</li>
//             <li>Draw on a library of forty-eight competencies or incorporate your own to identify consistent benchmarks that determine job success</li>
//         </ul>
//       </TabPanel>
//       <TabPanel value={value} index={1}>
//         Item Two
//       </TabPanel>
//       <TabPanel value={value} index={2}>
//         Item Three
//       </TabPanel>
//       <TabPanel value={value} index={3}>
//         Item Four
//       </TabPanel>
//     </Container>
//   );
// }

export default TrainingServices;
