import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  useMediaQuery,
  Slider,
  Typography, Button, ButtonGroup
} from "@material-ui/core";
import TabPanelFilter from "../../Components/FilterDialog/TabPanelFilter";
import FilterOptions from "../../Components/FilterDialog/FilterOptions";
import jobCategories from '../../assets/jobCategories.json';
import experienceOptions from '../../assets/experiences.json';
import jobTypeOptions from '../../assets/jobTypes.json';
import educationOption from '../../assets/educations.json';

function a11yProps(index) {
  return {
    id: `vertical-tab-filter-${index}`,
    "aria-controls": `vertical-tabpanel-filter-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  closeButton: {
    float: "right",
    cursor: "pointer"
  },
  salarySlider: {
    width: "40vw"
  },
  dialogPaper : {
    [theme.breakpoints.up(780)]:{ minHeight: '75vh',
    maxHeight: '75vh'}
  }
}));

const jobCategoriesOption = jobCategories.map(value => ({ "TextName": value, "ValueId": value }))


function FilterDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, setSelectedValueFilter } = props;
  // let { salary, category, experience, jobType, education } = selectedValue;
  const [salary, setSalary] = React.useState(selectedValue.salary);
  const [category, setCategory] = React.useState(selectedValue.category);
  const [experience, setExperience] = React.useState(selectedValue.experience);
  const [jobType, setJobType] = React.useState(selectedValue.jobType);
  const [education, setEducation] = React.useState(selectedValue.education);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  //   const handleChange = (event) => {
  //     onClose(event.target.value);
  //   };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  function salaryvaluetext(value) {
    setSalary(value);
    return `${value/1000}k`;
  }
  const changeJobCategory = (value)=>{
    setCategory(value);
  }
  const changeJobType = (value)=>{
    setJobType(value);
  }
  const changeExperience = (value)=>{
    setExperience(value);
  }
  const changeEducation = (value)=>{
    setEducation(value);
  }

  const setFilterOption = ()=>{
    setSelectedValueFilter({salary, category, jobType, experience, education});
    // setRedirect(true);
    handleClose();
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="filter-dialog-title"
      open={open}
      // className = {classes.dialogPaper}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="filter-dialog-title">Filter<span className={classes.closeButton} onClick={handleClose}><i className="fa fa-times-circle"></i></span></DialogTitle>
      <DialogContent className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs filter"
          className={classes.tabs}
        >
          <Tab label="Salary" {...a11yProps(0)} />
          <Tab label="Category" {...a11yProps(1)} />
          <Tab label="Experience" {...a11yProps(2)} />
          <Tab label="Job Type" {...a11yProps(3)} />
          <Tab label="Education" {...a11yProps(4)} />
        </Tabs>
        <TabPanelFilter value={value} index={0}>
          <div className={classes.salarySlider}>
          <Typography id="discrete-slider-salary" gutterBottom>
            Salary (set to 0 to filter job irrespective of salary)
          </Typography>
          <Slider
            key="slider-salary"
            track={false}
            min={0}
            max={100}
            scale={(x) => x * 1000}
            defaultValue={salary/1000}
            getAriaValueText={salaryvaluetext}
            valueLabelFormat={salaryvaluetext}
            aria-labelledby="discrete-slider-salary"
            step={1}
            valueLabelDisplay="auto"
            marks={[
              {
                value: 0,
                label: 'Rs. 0',
              },
              {
                value: 100,
                label: 'Rs. 100000',
              },
            ]}
          />
          <Typography gutterBottom>
            Rs. {salary}
          </Typography>
          </div>
        </TabPanelFilter>
        <TabPanelFilter value={value} index={1}>
          <FilterOptions option={jobCategoriesOption} handleChange={changeJobCategory} value={category}></FilterOptions>
        </TabPanelFilter>
        <TabPanelFilter value={value} index={2}>
          <FilterOptions option={experienceOptions} handleChange={changeExperience} value={experience}></FilterOptions>
        </TabPanelFilter>
        <TabPanelFilter value={value} index={3}>
          <FilterOptions option={jobTypeOptions} handleChange={changeJobType} value={jobType}></FilterOptions>
        </TabPanelFilter>
        <TabPanelFilter value={value} index={4}>
          <FilterOptions option={educationOption} handleChange={changeEducation} value={education}></FilterOptions>
        </TabPanelFilter>
      </DialogContent>
      <DialogActions>
      <ButtonGroup color="primary" aria-label="contained primary button group" style={{width:"100%"}}>
        <Button style={{width:"50%"}} onClick={handleClose}>Close</Button>
        <Button style={{width:"50%"}} variant="contained" onClick={setFilterOption}>Apply</Button>
        {/* {redirect && <Redirect to={`/findjobs/${city}/${salary}/${category}/${jobType}/${experience}/${education}`} />} */}
      </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}

export default FilterDialog;
