import React,{useState} from "react";
import { Button } from "@material-ui/core";
import { useSnackbar } from "material-ui-snackbar-provider";
import { putRequest } from "../../Utils/fetch";
import Loader from "../../Components/Loader";
import {useHistory} from "react-router";

function ApplyNowBtn(props) {
  const snackbar = useSnackbar();
  const history = useHistory();
  const handleClick = async (event) => {
    snackbar.showMessage("...Loading!");
    event.preventDefault();
    let body = { jobId: props.jobId };
    try {
      let response = await putRequest("/jobs/jobapplication", body);
      console.log(response);
      if (response.status === "success") {snackbar.showMessage("You have successfully applied for this job!!");}
      else {
        snackbar.showMessage(response.message);
        if(response.message.search('Profile') !== -1) history.push('/profile');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Button size="small" variant="outlined" onClick={handleClick}>
         <i className="fas fa-paper-plane"></i>Apply Now
      </Button>
    </React.Fragment>
  );
}

export default ApplyNowBtn;
