import React from 'react';
import HomeSection from './HeroSection';
import './Home.css';
import HowItWorks from "./HowItWorks";
import JobCategories from './JobCategories';

export default function Index(props) {
    return (
        <>
            <HomeSection />
            <HowItWorks/>
            <JobCategories />
        </>
    )
}
