import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    field: {
        paddingRight: '5px',
        color: '#8e9a9d !important',
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
            marginBottom: '1rem',
        },
    },
    value: {
        marginBottom: '1rem',
    }
}))
export default function FormField({field, formElement, editMode, value, required = false}) {
    const classes = useStyles();
    return (
        <Grid container spacing={1}>
            <Grid item md={3} container direction={"column"}>
                <p className={classes.field}>{field}{required && "*"}</p>
            </Grid>
            <Grid item md={9} container direction={"column"} className={classes.value}>
                {editMode ? formElement : value}
            </Grid>
        </Grid>
    )
}
