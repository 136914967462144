import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery} from "@material-ui/core";
import React from "react";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    closeButton: {
        float: "right",
        cursor: "pointer"
    },
    salarySlider: {
        width: "40vw"
    },
    dialogPaper : {
        [theme.breakpoints.up(780)]:{ minHeight: '75vh',
        maxHeight: '75vh'}
      }
}));

export default function FilterDialog({onClose,selectedValue,open,setFilterOption, ...props}) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        onClose(selectedValue);
    };
    return  (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-labelledby="filter-dialog-title"
            open={open}
            classes= {{paper: classes.dialogPaper}}
        >
            <DialogTitle id="filter-dialog-title">Filter<span className={classes.closeButton} onClick={handleClose}><i className="fa fa-times-circle"></i></span></DialogTitle>
            <DialogContent className={classes.root}>
                {props.children}
            </DialogContent>
            <DialogActions>
                <ButtonGroup color="primary" aria-label="contained primary button group" style={{width:"100%"}}>
                    <Button style={{width:"50%"}} onClick={handleClose}>Close</Button>
                    <Button style={{width:"50%"}} variant="contained" onClick={setFilterOption}>Apply</Button>
                    {/* {redirect && <Redirect to={`/findjobs/${city}/${salary}/${category}/${jobType}/${experience}/${education}`} />} */}
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    )
}
