import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@material-ui/core";
import React, {useState} from "react";
import {getRequest} from "../../Utils/fetch";
import {useSnackbar} from "material-ui-snackbar-provider";

export default function ViewContactBtn({user, ...props}) {

    const [open, setOpen] = React.useState(false);
    const snackbar = useSnackbar();
    let [details,setDetails] = useState({});
    const handleClickOpen = async () => {
        const params = {
            applicantId: user._id,
        }
        const resp = await getRequest('/applicants/profiles',params);
        if(resp['status'] !== 'success' || !resp['data']) {
            snackbar.showMessage(resp['message'] || 'Some Error Occurred while fetching details');
        }
        else {
            setDetails({
                email: resp.data.email,
                mobile: resp.data.mobile,
                whatsapp: resp.data.whatsapp,
            });
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant="outlined" onClick={handleClickOpen}>
                View Contact
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="view-contact" fullWidth maxWidth="sm">
                <DialogTitle id="view-contact-title">View Contact</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography><Typography display="inline" style={{color: 'black'}}>Email:</Typography> {details.email}</Typography>
                        <Typography><Typography display="inline" style={{color: 'black'}}>Mobile:</Typography> {details.mobile}</Typography>
                        <Typography><Typography display="inline" style={{color: 'black'}}>Whatsapp:</Typography> {details.whatsapp}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
