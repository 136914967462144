import React from "react";
import "./JobCategories.css";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import {JobCategoriesData} from "./JobCategoriesData";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    content: {
        background: "#1c2237",
        color: "#fff",
    },
    media: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: 100
    }
});

function JobCategories() {
    const classes = useStyles();

    return (
        <div className="home__job-categories home__section">
            <div className="home__section__heading">
                <h1>Job Categories</h1>
            </div>
            <div className="grid-spacing">
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={4}
                >
                    {JobCategoriesData.map((data) => (
                        <Grid item xs={8} md={3} sm={6} key={data.id}>
                            <Link to={'/findjobs?category=' + data.name} style={{ textDecoration: 'none' }}>
                                <Card>
                                    <CardActionArea>
                                        <CardMedia className={classes.media}
                                                   component="img"
                                                   alt={data.name}
                                                   height="100"
                                                   image={data.img__path}
                                                   title={data.name}

                                        />
                                        <CardContent className={classes.content}>
                                            <Typography gutterBottom variant="overline">
                                                {data.name}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
}

export default JobCategories;
