import React, {useEffect} from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import {Router, Switch, Route} from 'react-router-dom';
import Footer from './Components/Footer/Footer'
import {AuthProvider} from './Context'
import AppRoute from "./Components/AppRoute";
import routes from "./Config/routes"
import history from "./Utils/history";
import {SnackbarProvider} from 'material-ui-snackbar-provider'
import Home from './Pages/Home'
import {getRequest} from "./Utils/fetch";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import CheckLoginStatus from "./Components/CheckLoginStatus";
import themeStyle from './Styling/theme.json';
import GoogleOneTap from "./Components/GoogleOneTap";

const theme = createMuiTheme(themeStyle);

function App() {

    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <SnackbarProvider SnackbarProps={{autoHideDuration: 4000}}>
                    <Router history={history}>
                        <>
                            <CheckLoginStatus/>
                            {/*<GoogleOneTap/>*/}
                            <Navbar/>
                            <Switch>
                                <Route path="/" render={Home} exact/>
                                {routes.map((route) => (
                                    <AppRoute
                                        key={route.path}
                                        path={route.path}
                                        component={route.component}
                                        isPrivate={route.isPrivate}
                                    />
                                ))}
                            </Switch>
                            <Footer/>
                        </>
                    </Router>
                </SnackbarProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
