import React from 'react';
import './Button.css'
import { Link } from 'react-router-dom';

export function Button(props) {
    const {location,name} = props
    return (
        <Link to={location}>
            <button className='btn nav-btn' {...props}>{name}</button>
        </Link>
    );
}
