import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader, Checkbox,
    Grid,
    IconButton, InputLabel,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";
import {FaUserGraduate,FaHistory,FaAddressBook,FaLightbulb} from 'react-icons/fa'
import {GoVerified} from 'react-icons/go'
import ViewContactBtn from "./ViewContactBtn";
import SendEmailModal from "./SendEmailModal";
import JobCard from "../JobCard";
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        marginBottom: "1rem"
    },
    avatar: {
        backgroundColor: red[500],
    },
    inputLabel: {
        display: "inline",
        color: "rgba(0, 0, 0, 0.5)",
        fontWeight: "900",
        fontFamily: "Font Awesome 5 Free"
    }
}));
function getAge(dateString) {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const ProfileCard= React.forwardRef(({user, sendEmailModal,onSelect, onDeSelect,onDownloadResume,selectAll,setSelectAll, ...props}, ref) => {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
        if(event.target.checked) onSelect();
        else onDeSelect();
        if(!event.target.checked) setSelectAll(false);
    }
    useEffect(() => {
        if(selectAll) {
            setChecked(true);
            onSelect();
        } else if(!selectAll) {
            setChecked(false);
            onDeSelect();
        }
    },[selectAll]);
    return (
        <Grid ref={ref} container direction="row" justify="center" alignItems="center">
            <Grid item md={12} xs={12}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={
                            <div>
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={handleCheckboxChange}
                                    checked={checked}
                                />
                                <div style={{display: 'inline-block'}}>
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        {user && user.name[0]}
                                    </Avatar>
                                </div>
                            </div>
                        }
                        // action={
                        //     <IconButton aria-label="settings">
                        //         <MoreVertIcon />
                        //     </IconButton>
                        // }
                        title={<div>
                            <span style={{verticalAlign: "middle"}}>{user.name}</span>
                            {/*<GoVerified style={{color: "blue", verticalAlign: "middle", margin: "2px"}}/>*/}
                        </div>}
                        // action={
                        //     <Checkbox
                        //         color="primary"
                        //         inputProps={{ 'aria-label': 'secondary checkbox' }}
                        //         onChange={handleCheckboxChange}
                        //     />
                        // }
                        subheader={(user.dob ? getAge(user.dob) + " Years, ": '') + (user.gender? capitalizeFirstLetter(user.gender):'')}
                    />
                    <CardContent >
                        <Grid container>
                            {
                                user.education && user.education.length!==0 &&
                                <Grid item md={6} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i className="fas fa-user-graduate">EDUCATION:</i></InputLabel>
                                    <Typography display="inline">
                                        {user.education && user.education.map((item,index) => (
                                            <>
                                                {index === 0 ?'':', '}
                                                {item.program} {item.course}
                                            </>
                                        ))}
                                    </Typography>
                                </Grid>
                            }
                            {
                                user.experience && user.experience.length!==0 &&
                                <Grid item md={6} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i className="fas fa-history">EXPERIENCE:</i></InputLabel>
                                    <Typography display="inline">
                                        {user.experience && user.experience.map((item,index) => (
                                            <>
                                                {index === 0 ?'':', '}
                                                {item.company} {item.role}-{item.duration && (item.duration + " Years")}
                                            </>
                                        ))}
                                    </Typography>
                                </Grid>
                            }
                        {/*</Grid>*/}
                        {/*<Grid container>*/}
                            {
                                (user.locality || user.city) &&
                                <Grid item md={12} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i className="fas fa-address-book">CITY:</i></InputLabel>
                                    <Typography display="inline">
                                        {user.locality && (user.locality + ',')} {user.city}
                                    </Typography>
                                </Grid>
                            }

                        {/*</Grid>*/}
                        {/*<Grid container>*/}
                            {
                                user.skills && user.skills.length!== 0 &&
                                <Grid item md={12} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i className="fas fa-lightbulb">SKILLS:</i></InputLabel>
                                    <Typography display="inline">
                                        {user.skills && user.skills.map((skill,index) => (
                                            <>
                                                {index === 0?'':', '}
                                                {skill}
                                            </>
                                        ))}
                                    </Typography>
                                </Grid>
                            }
                            {
                                user.desiredJobDetails &&
                                <Grid item md={12} xs={12}>
                                    <InputLabel style={{display: "inline"}}><i className="fas fa-briefcase">DESIRED JOB: </i></InputLabel>
                                    <Typography display="inline">
                                        {user.desiredJobDetails.department}-{user.desiredJobDetails.role}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button variant="outlined" onClick={() => sendEmailModal([{name: user.name, user: user.user}])}>
                            Send Email
                        </Button>
                        <ViewContactBtn user={user}/>
                        <Button variant="outlined" onClick={onDownloadResume}>
                            Download Resume
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
})

export default ProfileCard;
