import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog,DialogContent, DialogActions } from "@material-ui/core";
import { Radio,RadioGroup, FormControl,FormControlLabel,FormLabel } from '@material-ui/core';

const useStyles = makeStyles({
  closeButton: {
    marginLeft: "30px",
    cursor: "pointer"
  },
});

function SortDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleChange = (event) => {
    onClose(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="sort-dialog-title"
      open={open}
    >
      {/* <DialogTitle id="sort-dialog-title">Sort</DialogTitle> */}
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Sort By <span className={classes.closeButton} onClick={handleClose}><i className="fa fa-times-circle"></i></span></FormLabel>
          <RadioGroup aria-label="sort" name="sort1" value={selectedValue} onChange={handleChange}>
            {/* <FormControlLabel value="popular" control={<Radio />} label="Popular" /> */}
            <FormControlLabel value="-date" control={<Radio />} label="Recent" />
            <FormControlLabel value="date" control={<Radio />} label="Oldest" />
          </RadioGroup>
      </FormControl>
      </DialogContent>
      <DialogActions>

      </DialogActions>
    </Dialog>
  );
}

export default SortDialog;
