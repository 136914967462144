import SignIn from "../../Components/SignIn/index.";
import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPassword() {
    return (
        <SignIn heading={"Reset Password"}>
            <ResetPasswordForm/>
        </SignIn>
    )
}
