import React from 'react'
import {Dialog, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

function HireNowDialog(props) {
    
    function onClose(){
        props.handleClose(false);
    }

    return (
        <Dialog
        open={props.open}
        onClose={onClose}
        aria-labelledby="hire-dialog-title"
        aria-describedby="hire-dialog-description"
      >
        <DialogTitle id="hire-dialog-title">{"Hire Now"}<i className="fa fa-times-circle" style={{float:"right", cursor:"pointer"}} onClick={onClose}></i></DialogTitle>
        <DialogContent>
          <DialogContentText id="hire-dialog-description">
              Share Job details for free Job post <a href="mailto:hr@myniyukti.com">Hr@myniyukti.com</a>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
}

export default HireNowDialog;
