import React, {useState} from "react";
import Box from './Box';
import {Button, FormControl, MenuItem, Select, Typography} from "@material-ui/core";
import FormField from "./FormField";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';
import {makeStyles} from "@material-ui/core";
import {useForm} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import CitiesList from "../../assets/CitiesList.json";
import {Autocomplete} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    pickers: {},
    editFormButtons: {
        margin: theme.spacing(1)
    },
    error: {
        color: "red",
    }
}))


export default function PersonalBox(props) {
    const {data: finalDetails, setFinalData: setFinalDetails} = props;
    const [editMode, setEditMode] = useState(false);
    const classes = useStyles();

    const {register, handleSubmit, errors, control} = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    // const [finalDetails, setFinalDetails] = useState({
    //     "name": "Prashant Shakya",
    //     "email": "prashushakya@gmail.com",
    //     "mobile": "9005902201",
    //     "dob": "Fri Apr 5 1998 00:00:00 GMT+0530 (India Standard Time)",
    //     "gender": "male",
    //     "location": "Sitapur",
    //     "resume": "Resume.pdf"
    // });
    // const [fileInput, setFileInput] = useState();
    const [details, setDetails] = useState(finalDetails);
    const handleDetailsChange = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value,
        })
    };
    const handleDobChange = (date) => {
        setDetails({
            ...details,
            dob: date
        })
    }
    const handleCityChange = (e, option) => {
        setDetails({
            ...details,
            city: (option && option.TextName)?option.TextName: null,
        })
    }
    // const resumeSelectedHandler = (e) => {
    //     console.log(e.target.files);
    //     setDetails({
    //         ...details,
    //         'resume': e.target.files[0]
    //     })
    // }
    const fields = {
        "Name": [
            <input type="text" placeholder="Enter Your Name" value={details["name"]} name="name"
                   onChange={handleDetailsChange}/>,
            <Typography>{finalDetails["name"]}</Typography>
        ],
        "Email": [
            <input type="text" placeholder="Enter Your Personal Email" value={details["email"]}
                   onChange={handleDetailsChange} name="email" disabled/>,
            <Typography>{finalDetails["email"]}</Typography>
        ],
        "Mobile": [
            <>
                <input type="text" placeholder="Enter Your Personal Mobile No" onChange={handleDetailsChange}
                       value={details["mobile"]}
                       name="mobile" ref={register({
                    // required: 'You must provide a 10 digit mobile number!',
                    pattern: {
                        value: /^\d{10}$/,
                        message: 'You must provide a valid 10 digit mobile number',
                    },
                })}
                       error={!!errors.mobile}/>
                {errors.mobile && (<span className={classes.error}>{errors.mobile.message}</span>)}
            </>,
            <Typography>{finalDetails && finalDetails.mobile}</Typography>
        ],
        "Whatsapp": [
            <>
                <input type="text" placeholder="Enter Your Personal Whatsapp No" onChange={handleDetailsChange}
                       value={details["whatsapp"]}
                       name="whatsapp" ref={register({
                    // required: 'You must provide a 10 digit mobile number!',
                    pattern: {
                        value: /^\d{10}$/,
                        message: 'You must provide a valid 10 digit whatsapp number',
                    },
                })}
                       error={!!errors.whatsapp}/>
                {errors.whatsapp && (<span className={classes.error}>{errors.whatsapp.message}</span>)}
            </>,
            <Typography>{finalDetails && finalDetails.whatsapp}</Typography>
        ],
        "Date of Birth": [
            <KeyboardDatePicker id="date-of-birth-picker" format="dd/MM/yyyy" value={(finalDetails["dob"] && finalDetails["dob"]) && (new Date(finalDetails["dob"]))}
                                name="dob" onChange={handleDobChange} className={classes.pickers}/>,
            <Typography>{(finalDetails["dob"] && finalDetails["dob"]) && (new Date(finalDetails["dob"])).toDateString()}</Typography>
        ],
        "Gender": [
            <FormControl>
                <Select
                    labelId="gender-select"
                    id="gender-select"
                    value={finalDetails.gender}
                    onChange={handleDetailsChange}
                    name="gender"
                >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                </Select>
            </FormControl>,
            <Typography>{finalDetails["gender"]}</Typography>
        ],
        "City": [
            <div>
            <Autocomplete
                disablePortal
                className={classes.selectCity}
                options={CitiesList}
                fullWidth
                value={{TextName: details["city"]}}
                getOptionLabel={(option) => option.TextName}
                onChange={handleCityChange}
                renderInput={(params) => <TextField variant="standard" {...params}/>}
            /></div>,
            <Typography>{finalDetails["city"]}</Typography>
        ],
        "Locality": [
            <input type="text" placeholder="Enter Your Locality" value={details["locality"]}
                   onChange={handleDetailsChange} name="locality"/>,
            <Typography>{finalDetails["locality"]}</Typography>
        ],
        // "Resume": [
        //     <>
        //         <input type="file"
        //                accept="application/pdf, application/vnd.ms-excel" onChange={resumeSelectedHandler}/>
        //         {/*<Button variant="contained" color="primary" className={classes.uploadResumeBtn}*/}
        //         {/*        onClick={fileInput.click()}>Pick a File</Button>*/}
        //     </>
        //     ,
        //     <Typography>{finalDetails['resume']}</Typography>
        // ]
    }
    const onClickSave = () => {
        console.log(details);
        setFinalDetails('personal', details);
        setEditMode(false);
    }
    const onSubmit = async (data) => {
        setFinalDetails('personal', details);
        setEditMode(false);
    };
    const onClickEdit = () => {
        setEditMode(!editMode);
        setDetails(finalDetails);
    };

    const editFormButtons =
        <div>
            <Button variant="contained" color="primary" className={classes.editFormButtons}
                    type={"submit"}>Save</Button>
            <Button variant="contained" color="default" className={classes.editFormButtons}
                    onClick={onClickEdit}>Cancel</Button>
        </div>

    return (
        <Box heading={"Personal"} setEditMode={setEditMode} editMode={editMode} onClickEdit={onClickEdit}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    {Object.keys(fields).map((field, index) => (
                        <FormField key={index} field={field} formElement={fields[field][0]} value={fields[field][1]}
                                   editMode={editMode} required/>
                    ))}
                    <FormField field="" formElement={editFormButtons} value={""} editMode={editMode}/>
                </form>
            </MuiPickersUtilsProvider>
        </Box>
    )
}
