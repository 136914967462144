import React, {useState} from "react";
import {Button, Grid, IconButton, makeStyles, TextField, Typography} from "@material-ui/core";
import Box from "./Box";
import {FaTimes} from "react-icons/fa";
import {Autocomplete} from "@material-ui/lab";
import specs from '../../assets/specs.json';

const useStyles = makeStyles((theme) => ({
    pickers: {},
    editFormButtons: {
        margin: theme.spacing(1)
    },
    input: {
        margin: '5px 20px',
        width: '90%'
    }
}))

// const finalDetails = [
//     {
//         specialization: "CSE",
//         institute: "MNNIT",
//         courseType: "Full Time",
//         passingYear: "2021"
//     },
//     {
//         specialization: "CSE",
//         institute: "MNNIT",
//         courseType: "Full Time",
//         passingYear: "2021"
//     },
// ];
export default function EducationBox(props) {
    const {data: finalDetails, setFinalData: setFinalDetails} = props;
    const [editMode, setEditMode] = useState(false);
    const [details, setDetails] = useState(finalDetails);
    const [newEducation, setNewEducation] = useState({
        program: '',
        institute: '',
        passingYear: '',
        course: '',
        educationLevel: 0
    });
    const classes = useStyles();
    const onClickEdit = () => {
        setEditMode(!editMode);
        setDetails(finalDetails);
    };
    const onChangeNewEducation = (e) => {
        setNewEducation({
            ...newEducation,
            [e.target.name]: e.target.value,
        })
    }
    const onClickAdd = (e) => {
        setDetails([
            ...details,
            newEducation
        ])
    }
    const onClickSave = (e) => {
        setFinalDetails('education', details);
        setEditMode(false);
    }
    const deleteEducation = (index) => {
        console.log(index);
        setDetails(details.filter((_,i) => i!==index));
    }
    const editFormButtons =
        <div>
            <Button variant="contained" color="primary" className={classes.editFormButtons} onClick={onClickSave}>Save</Button>
            <Button variant="contained" color="default" className={classes.editFormButtons} onClick={onClickEdit}>Cancel</Button>
        </div>
    return (
        <>
            <Box heading="Education*" editMode={editMode} setEditMode={setEditMode} onClickEdit={onClickEdit}>
                <Grid container direction="column">
                    {details.map((education, index) => (

                        <Grid xs={12} item container key={index}>
                            <Grid key={index} xs={6} item>
                                <Typography style={{fontWeight: "bold"}}>{education['program']}</Typography>
                                <Typography>{education['institute']} {education['passingYear']} {education['course']} </Typography>
                            </Grid>
                            <Grid xs={2} item>
                                {editMode ?
                                    <IconButton color="secondary" aria-label="Remove Skill" onClick={() => deleteEducation(index)}>
                                        <FaTimes className={classes.deleteSkillButton}/>
                                    </IconButton>
                                    :
                                    ""
                                }
                            </Grid>
                        </Grid>

                    ))}

                    {editMode &&

                        <form noValidate autoComplete="off" className={classes.form}>
                            <Grid item container>
                            <Grid item md={6} sm={12}>
                                <Autocomplete
                                    className={classes.input}
                                    renderInput={(params) => <TextField variant="standard" {...params} label="Specialization"/>}
                                    options={specs}
                                    getOptionLabel={(option) => option['course']}
                                    groupBy={(option) => option['program']}
                                    onChange={(e,option) => {setNewEducation({...newEducation, program: option.program, course: option.course, educationLevel: option.ValueId})}}
                                />
                                {/*// <TextField className={classes.input} value={newEducation.program} name="program"*/}
                                {/*                                 onChange={onChangeNewEducation}*/}
                                {/*                                 label="Program"/>*/}
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <TextField className={classes.input} value={newEducation.institute} name="institute"
                                                                 onChange={onChangeNewEducation}
                                                                 label="Institute"/>
                            </Grid>
                            {/*<Grid item md={6} sm={12}>*/}
                                {/*<TextField className={classes.input} value={newEducation.course} name="course"*/}
                                {/*                                 onChange={onChangeNewEducation} label="Course"/>*/}
                            {/*</Grid>*/}
                            <Grid item md={6} sm={12}>
                                <TextField className={classes.input} value={newEducation.passingYear} name="passingYear"
                                                                 onChange={onChangeNewEducation} label="Passing Year"/>
                            </Grid>
                            <Button variant="outlined" color="primary" className={classes.editFormButtons} onClick={onClickAdd}>Add</Button>

                            </Grid>
                        </form>
                    }
                    {editMode && editFormButtons}
                </Grid>
            </Box>
        </>
    );
}
